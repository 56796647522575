<template>
  <v-app>

    <v-container fluid grid-list-xl>
    <v-layout row wrap  class="dashbord">
      <!-- Widgets-->
      <v-flex  lg3 sm6 xs12 v-for="item in items" :key="item.title">
        <router-link :to="item.link">
        <widget :icon="item.icon" subTitle="" :title="item.title" :supTitle="''+item.count" :color="item.color"/>
        </router-link>
      </v-flex>


      <!-- Widgets Ends -->
    </v-layout>
  </v-container>

  </v-app>
</template>

<script>
import Widget from "@/components/Widget";
import axios from "axios";
let table = '/dashboard';

let token = localStorage.getItem('token');

export default {
  components:{Widget},
  data() {
    return {
      apiUrl:process.env.VUE_APP_API_URL,
      lorem: `Lorem ipsum dolor sit amet, mel at clita quando.`,
       items: [], 
     /* items: [
        //{ title: 'Dashboard', icon: 'mdi-view-dashboard', color: '#00b297'},
        { title: 'Lieux à visiter', icon: 'mdi-image' , color: '#dc3545' , link :'/lieux-a-visiter',count:this.liste.lieux},
        { title: 'Gastronomie', icon: 'mdi-food-turkey', color: '#c42f87' , link :'/gastronomies',count:this.liste.gastronomies},
        { title: 'Evènements', icon: 'mdi-calendar-search', color: '#cab277' , link :'/evenements',count:this.liste.evenements},
       // { title: 'Témoignages', icon: 'mdi-format-list-bulleted', color: '#555297' , link :'/temoignages'},
        { title: 'Actualités', icon: 'mdi-clipboard-list', color: '#0866C6' , link :'/actualites',count:this.liste.actualites},
        { title: 'Partages', icon: 'mdi-share-variant-outline', color: '#1f2650' , link :'/partages',count:this.liste.partages},
        { title: 'Hôtels', icon: 'mdi-office-building', color: '#272727' , link :'/hotels',count:this.liste.hotels},
        { title: 'Riads', icon: 'mdi-home-modern', color: '#32acb2' , link :'/riads',count:this.liste.riads},
        { title: 'Maisons d\'hôtes', icon: 'mdi-warehouse', color: '#D4AA7D' , link :'/maisons-hotes',count:this.liste.maisonhotes},
        { title: 'Restaurants', icon: 'mdi-silverware', color: '#EFD09E' , link :'/restaurants',count:this.liste.restaurants},
        { title: 'Activité Touristique', icon: 'mdi-signature-image', color: '#D2D8B3' , link :'/activites-touristiques',count:this.liste.activites},
        { title: 'Guide Touristique', icon: 'mdi-nature-people', color: '#90A9B7' , link :'/guides-touristiques',count:this.liste.guides},
      ],*/
      liste:[],
    }
  },
  methods:{

  loadItems() {

      axios.get(this.apiUrl+table,
          { headers: { Authorization: "Bearer " + token }})
          .then((response) => {            
                this.liste = response.data.totals;
                   this.items = [
        //{ title: 'Dashboard', icon: 'mdi-view-dashboard', color: '#00b297'},
        { title: 'Lieux à visiter', icon: 'mdi-image' , color: '#dc3545' , link :'/lieux-a-visiter',count:this.liste.lieux},
        { title: 'Gastronomie', icon: 'mdi-food-turkey', color: '#c42f87' , link :'/gastronomies',count:this.liste.gastronomies},
        { title: 'Evènements', icon: 'mdi-calendar-search', color: '#cab277' , link :'/evenements',count:this.liste.evenements},
       // { title: 'Témoignages', icon: 'mdi-format-list-bulleted', color: '#555297' , link :'/temoignages'},
        { title: 'Actualités', icon: 'mdi-clipboard-list', color: '#0866C6' , link :'/actualites',count:this.liste.actualites},
        { title: 'Partages', icon: 'mdi-share-variant-outline', color: '#1f2650' , link :'/partages',count:this.liste.partages},
        { title: 'Hôtels', icon: 'mdi-office-building', color: '#272727' , link :'/hotels',count:this.liste.hotels},
        { title: 'Riads', icon: 'mdi-home-modern', color: '#32acb2' , link :'/riads',count:this.liste.riads},
        { title: 'Maisons d\'hôtes', icon: 'mdi-warehouse', color: '#D4AA7D' , link :'/maisons-hotes',count:this.liste.maisonHotes},
        { title: 'Restaurants', icon: 'mdi-silverware', color: '#EFD09E' , link :'/restaurants',count:this.liste.restaurants},
        { title: 'Activité Touristique', icon: 'mdi-signature-image', color: '#D2D8B3' , link :'/activites-touristiques',count:this.liste.activites},
        { title: 'Guide Touristique', icon: 'mdi-nature-people', color: '#90A9B7' , link :'/guides-touristiques',count:this.liste.guides},
      ];
          }).catch((error) => {
        console.log(error)
      })
    },

  },
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout')
    this.loadItems();
  },
}
</script>

<style>

.dashbord a{
color:white!important;
  text-decoration: none;
}


</style>

