<template>
  <v-app>
    <v-main class="container align-center px-1">
      <h2 class="font-weight-medium mb-2">
        <v-icon>mdi-arrow-right</v-icon>
        Modifier Restaurant
      </h2>
      <div>
        <v-alert v-model="alert" dismissible color="green" border="left" elevation="2" colored-border type="success">
          <strong>{{ msgSuccess }}</strong>
        </v-alert>
      </div>
      <!--- form -->
      <v-card>
        <v-card-title>
          <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <label for="drpafficher">Afficher</label>
              <select class="form-control" v-model="editedItem.display" id="drpafficher">
                <option value="1">Oui</option>
                <option value="0">No</option>
              </select>
            </v-col>
            <v-col cols="12" sm="12">
              <label for="drpafficher">Afficher sur la Page d'Accueil</label>
              <select class="form-control" v-model="editedItem.display_home" id="drpafficher">
                <option value="1">Oui</option>
                <option value="0">No</option>
              </select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.nom" label="Nom"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.nom_ar" label="Nom Arabe"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.nom_en" label="Nom Anglais"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="editedItem.description" label="Description"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="editedItem.description_ar" label="Description Arabe"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="editedItem.description_en" label="Description Anglais"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <label for="drpafficher">Ville</label>
              <select class="form-control" v-model="editedItem.ville_id" id="drpafficher">
                <option v-for="ville in villes" :key="ville.id" :value="ville.id"
                  :selected="editedItem.ville_id === ville.id">
                  {{ ville.ville }}
                </option>
              </select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.tel" label="Numéro de téléphone "></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.website" label="Site web"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.adresse" label="Adresse"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.adresse_ar" label="Adresse(AR)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.map" label="Map"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.instagram" label="Instagram"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.whatsapp" label="Whatsapp"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.facebook" label="Facebook"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.tiktok" label="Tiktok"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field v-model="editedItem.meta_titre" label="Meta titre (FR)"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field v-model="editedItem.meta_titre_ar" label="Meta titre (AR)"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field v-model="editedItem.meta_description" label="Meta description (FR)"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field v-model="editedItem.meta_description_ar" label="Meta description (AR)"></v-text-field>
            </v-col>
            <!--
              <v-col cols="12" sm="12">
                <v-file-input
                    v-model="editedItem.image"
                    label="Image"
                    id="fileImage"
                    filled
                    prepend-icon="mdi-camera"
                ></v-file-input>
                        <div class="text-truncate text-center justify-center" :v-for="image in editedItem.images" >
                        <v-img  :src="Url+image.image" :alt="editedItem.nom" width="100px"></v-img>
                        </div>
              </v-col>
        -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12">
            <v-btn color="indigo" outlined @click="saveHeberegement()"><v-icon small
                class="mr-2">mdi-content-save</v-icon>
              Enregistrer</v-btn>
          </v-col>
        </v-card-actions>
        <v-snackbar :timeout="timeout" :value="saveSuccess" right top color="success" tile app>
          <strong>{{ msgSuccess }}</strong>
          <template v-slot:action="{ attrs }">
            <v-btn color="black" text v-bind="attrs" @click="saveSuccess = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
      <!--- end form -->
      <!--- images -->
      <v-card class="mt-3">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="6">
              <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
              Images :
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn color="success" outlined dark class="float-right ma-3" @click="ajouterImage()">
                Ajouter
                <v-icon small>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12">
              <v-file-input v-model="editedItem.image" label="Image" id="fileImage" filled multiple
                prepend-icon="mdi-camera"></v-file-input>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2" sm="2" align="left" v-for="image in editedItem.images" :key="image.id">
              <v-img :src="Url + image.image" :alt="editedItem.nom" style="max-height: 100px; margin: auto"></v-img>
              <v-btn @click="showDeleteImageDialog(image)" color=""><v-icon color="red">mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--- end images -->
      <!--- Entrée -->
      <v-card class="mt-3">
        <v-card-title>
          <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
          Entrée :
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <div class="d-flex">
                <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
                  Ajouter
                  <v-icon small>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span v-if="editedObj.id">Edit {{ editedObj.id }}</span>
                <span v-else>Create</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field v-model="editedObj.nom" label="Nom"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-textarea v-model="editedObj.description" label="Description"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field type="number" v-model="editedObj.prix" label="Prix"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showEditDialog(null, 1)">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="saveItem(editedObj, 1)">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Menu</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Prix</th>
                      <th class="text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsEntree" :key="item.id">
                      <td>{{ item.nom }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.prix }}</td>
                      <td>
                        <v-icon small class="mr-2" @click="showEditDialog(item, 1)" color="primary">
                          mdi-pencil
                        </v-icon>
                        <v-icon small @click="showDeleteDialog(item, 1)" color="pink">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- Plats --->
      <v-card class="mt-3">
        <v-card-title>
          <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
          Plats :
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog1" max-width="500px">
            <template v-slot:activator="{ on }">
              <div class="d-flex">
                <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
                  Ajouter
                  <v-icon small>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span v-if="editedObj.id">Edit {{ editedObj.id }}</span>
                <span v-else>Create</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field v-model="editedObj.nom" label="Nom"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-textarea v-model="editedObj.description" label="Description"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field type="number" v-model="editedObj.prix" label="Prix"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showEditDialog(null, 2)">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="saveItem(editedObj, 2)">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Menu</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Prix</th>
                      <th class="text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsPlats" :key="item.id">
                      <td>{{ item.nom }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.prix }}</td>
                      <td>
                        <v-icon small class="mr-2" @click="showEditDialog(item, 2)" color="primary">
                          mdi-pencil
                        </v-icon>
                        <v-icon small @click="showDeleteDialog(item, 2)" color="pink">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- Dessert -->
      <v-card class="mt-3">
        <v-card-title>
          <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
          Dessert :
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog2" max-width="500px">
            <template v-slot:activator="{ on }">
              <div class="d-flex">
                <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
                  Ajouter
                  <v-icon small>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span v-if="editedObj.id">Edit {{ editedObj.id }}</span>
                <span v-else>Create</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field v-model="editedObj.nom" label="Nom"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-textarea v-model="editedObj.description" label="Description"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-text-field type="number" v-model="editedObj.prix" label="Prix"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="showEditDialog(null, 3)">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="saveItem(editedObj, 3)">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Menu</th>
                      <th class="text-left">Description</th>
                      <th class="text-left">Prix</th>
                      <th class="text-left">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in itemsDessert" :key="item.id">
                      <td>{{ item.nom }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.prix }}</td>
                      <td>
                        <v-icon small class="mr-2" @click="showEditDialog(item, 3)" color="primary">
                          mdi-pencil
                        </v-icon>
                        <v-icon small @click="showDeleteDialog(item, 3)" color="pink">
                          mdi-delete
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- delete dialog image -->
      <v-dialog v-model="dialogImageDelete" max-width="500px">
        <v-card>
          <v-card-title>Delete Image</v-card-title>
          <v-card-text>Voulez-vous vraiment supprimer cet élément ?</v-card-text>
          <v-img :src="Url + imageToDelete.image" style="max-height: 100px; max-width: 100px; margin: auto"></v-img>
          <v-card-actions>
            <v-btn color="primary" text @click="dialogImageDelete = false">Close</v-btn>
            <v-btn color="primary" text @click="deleteImage()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- delete dialog menu -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>Delete</v-card-title>
          <v-card-text>Voulez-vous vraiment supprimer cet élément
            <strong> `{{ itemToDelete.nom }}` </strong> ?</v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="dialogDelete = false">Close</v-btn>
            <v-btn color="primary" text @click="deleteItem()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>
<script>
import axios from "axios";
import api from "@/apis/Api";
let table = "/restaurants";
let token = localStorage.getItem("token");
export default {
  name: "EditRestaurantComponent",
  created() {
    this.$store.commit("SET_LAYOUT", "app-layout");
  },
  data() {
    return {
      items: [],
      apiUrl: process.env.VUE_APP_API_URL,
      dialog: false,
      dialog1: false,
      dialog2: false,
      editedItem: {},
      dialogDelete: false,
      itemToDelete: {},
      Url: process.env.VUE_APP_API_URL_STORAGE,
      snackbar: false,
      msgSuccess: [],
      alert: false,
      saveSuccess: false,
      timeout: 3000,
      services: [],
      selectedColumns: [],
      editedObj: {},
      imageToDelete: {},
      dialogImageDelete: false,
      overlay: false,
      itemsEntree: [],
      itemsPlats: [],
      itemsDessert: [],
      villes: [],
    };
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    showEditDialog(item, category) {
      this.editedObj = item || {};
      if (category === 1)
        this.dialog = !this.dialog;
      else if (category === 2)
        this.dialog1 = !this.dialog1;
      else
        this.dialog2 = !this.dialog2;
    },
    loadItems() {
      this.items = [];
      axios
        .get(this.apiUrl + table + `/id/${this.id}`, {
          headers: { Authorization: "Bearer " + token },
        })
        .then((response) => {
          console.warn(response.data);
          this.editedItem = response.data.restaurant;
          this.villes = response.data.villes;
          const ligne_menus = response.data.ligne_menus;
          this.itemsEntree =
            ligne_menus.length > 0
              ? ligne_menus.filter((item) => item.categoriemenu.id == 1)[0]
                .menus
              : this.itemsEntree;
          this.itemsPlats =
            ligne_menus.length > 0
              ? ligne_menus.filter((item) => item.categoriemenu.id == 2)[0]
                .menus
              : this.itemsEntree;
          this.itemsDessert =
            ligne_menus.length > 0
              ? ligne_menus.filter((item) => item.categoriemenu.id == 3)[0]
                .menus
              : this.itemsEntree;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveHeberegement(item) {
      /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
      let method = "post";
      let id = this.editedItem.id;
      let url = this.apiUrl + table + `/${id}`;
      // save the record
      axios[method](url, this.editedItem, {
        headers: {
          Authorization: "Bearer " + token,
          //'Content-Type': 'multipart/form-data'
          //'Content-Type': imagefile.type
        },
      })
        .then((response) => {
          // add new item to state
          this.saveSuccess = true;
          this.msgSuccess = "Le contenu a été enregistré.";
        })
        .catch((error) => {
          this.msgErreur = error;
          console.log(error.response);
        });
    },
    saveItem(item, category) {
      /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
      let method = "post";
      let url = this.apiUrl + "/menus";
      let id = item.id;
      // airtable API needs the data to be placed in fields object
      let data = {
        fields: item,
      };
      /*
      const object = new FormData();
      object.append("type", data.fields.type);
      object.append("options", data.fields.options.toString());
      object.append("prix", data.fields.prix);
      object.append("id_hotel", this.editedItem.id);
*/
      if (id) {
        // if the item has an id, we're updating an existing item
        method = "put";
        url = this.apiUrl + `/menus/${id}`;
        // must remove id from the data for airtable patch to work
        delete data.fields.id;
      } else {
        item.id_categorie = category;
        item.id_restaurant = this.editedItem.id;
      }
      // save the record
      axios[method](url,
        item,
        {
          headers: {
            Authorization: "Bearer " + token,
            //'Content-Type': 'multipart/form-data'
            //'Content-Type': imagefile.type
          }
        }).then((response) => {
          //if (response.data) {
          // add new item to state
          if (method === 'post') {
            // add the new item to items state
            // AddMenu
            this.chargerTable(category, response.data.menu);
            this.editedObj.id = response.data.menu.id
          } else {
            this.editedObj.id = response.data.id
          }
          this.saveSuccess = true;
          this.msgSuccess = 'Le contenu a été enregistré.';
          this.editedObj = {};
          if (category === 1)
            this.dialog = !this.dialog;
          else if (category === 2)
            this.dialog1 = !this.dialog1;
          else
            this.dialog2 = !this.dialog2;
          //}
          // this.dialog = !this.dialog
        }).catch((error) => {
          this.msgErreur = error;
          console.log("Error");
          console.log(error.response)
        })
    },
    deleteItem() {
      console.log("deleteItem", this.itemToDelete);
      var index = -1;
      axios
        .delete(this.apiUrl + "/menus" + `/${this.itemToDelete.id}`, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.dialogDelete = false;
          this.saveSuccess = true;
          this.msgSuccess = "Le contenu a été supprimé.";
          if (this.itemToDelete.category === 1) {
            index = this.itemsEntree.indexOf(this.itemToDelete);
            this.itemsEntree.splice(index, 1);
          } else if (this.itemToDelete.category === 2) {
            index = this.itemsPlats.indexOf(this.itemToDelete);
            this.itemsPlats.splice(index, 1);
          } else {
            index = this.itemsDessert.indexOf(this.itemToDelete);
            this.itemsDessert.splice(index, 1);
          }
        });
      //this.items.splice(index, 1)
      //this.dialogDelete = false
    },
    chargerTable(category, obj) {
      if (category === 1)
        this.itemsEntree.push(obj);
      else if (category === 2)
        this.itemsPlats.push(obj);
      else
        this.itemsDessert.push(obj);
    },
    showDeleteDialog(item, category) {
      this.itemToDelete = item;
      this.itemToDelete.category = category;
      this.dialogDelete = !this.dialogDelete;
    },
    showDeleteImageDialog(item) {
      this.imageToDelete = item;
      this.dialogImageDelete = !this.dialogImageDelete;
    },
    deleteImage() {
      // console.log('deleteItem', this.imageToDelete)
      // const index = this.items.indexOf(this.imageToDelete)
      axios
        .delete(this.apiUrl + "/images" + `/${this.imageToDelete.id}`, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          //this.imageToDelete.images.splice(index, 1)
          this.dialogImageDelete = false;
          this.saveSuccess = true;
          this.msgSuccess = "Le contenu a été supprimé.";
          this.$router.go();
        });
    },
    ajouterImage() {
      this.overlay = true;
      const object = new FormData();
      object.append("id_restaurant", this.editedItem.id);
      var imagefile = document.querySelector("#fileImage");
      for (let i = 0; i < imagefile.files.length; i++) {
        object.append("images[]", imagefile.files[i]);
      }
      axios
        .post(this.apiUrl + "/images", object, {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": imagefile.type,
          },
        })
        .then((response) => {
          this.overlay = false;
          this.saveSuccess = true;
          this.msgSuccess = "Le contenu a été enregistré.";
          this.$router.go();
        })
        .catch(() => {
          this.overlay = false;
        })
        .finally(() => {
          this.overlay = false;
        });
    },
  },
  computed: {
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id;
    },
  },
};
</script>
<style scoped>
#drpafficher {
  width: 100%;
  padding: 7px;
  border: 1px solid;
  margin-top: 10px;
}
</style>
