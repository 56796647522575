<template>
    <v-app>
        <v-main class="container align-center px-1">
            <h2 class="font-weight-medium mb-2">
                <v-icon>mdi-arrow-right</v-icon>
                Slide Edit
            </h2>
            <v-card>
                <v-card-title>Edit Slide</v-card-title>
                <v-card-text>
                    <v-row>
                        <!-- Title French -->
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="form.title_fr" label="Titre Français"></v-text-field>
                        </v-col>

                        <!-- Title Arabic -->
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="form.title_ar" label="Titre Arabe"></v-text-field>
                        </v-col>

                        <!-- Title English -->
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="form.title_en" label="Titre Anglais"></v-text-field>
                        </v-col>

                        <!-- Image Path -->
                        <v-col cols="10" sm="10">
                            <v-file-input v-model="form.img_path" label="Image Path" filled
                                prepend-icon="mdi-camera"></v-file-input>
                        </v-col>
                        <v-col cols="2" sm="2">
                            <v-img v-if="form.img_path" :src="Url + form.img_path" max-height="100" max-width="100"
                                class="mt-3" />
                        </v-col>

                        <!-- Video Path -->
                        <v-col cols="10" sm="10">
                            <v-file-input v-model="form.video_path" label="Video Path" filled
                                prepend-icon="mdi-video"></v-file-input>
                        </v-col>
                        <v-col cols="2" sm="2">
                            <video v-if="form.video_path" :src="Url + form.video_path" controls width="200" height="150"
                                class="mt-3"></video>
                        </v-col>

                        <!-- Iframe -->
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="form.iframe" label="Iframe"></v-text-field>
                        </v-col>

                        <!-- Link -->
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="form.link"  label="Lien"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-select v-model="form.background" :items="backgroundOptions" item-text="text"
                                item-value="value" label="Sélectionnez l'arrière-plan" hide-details class="mt-2">
                                <template v-slot:selection="{ item }">
                                    <div style="display: flex; align-items: center;">
                                        <img :src="item.image" alt="Background"
                                            style="width: 35px; height: 24px; margin-right: 8px;" />
                                        {{ item.text }}
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div style="display: flex; align-items: center;">
                                        <img :src="item.image" alt="Background"
                                            style="width: 35px; height: 24px; margin-right: 8px;" />
                                        {{ item.text }}
                                    </div>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-checkbox v-model="form.active" :checked="form.active" label="Actif"></v-checkbox>
                            <!-- <v-checkbox v-model="form.active"  @change="toggleActive" label="Active"></v-checkbox> -->
                        </v-col>
                    </v-row>
                </v-card-text>

                <!-- Save Button -->
                <v-card-actions>
                    <v-btn :loading="loading" color="indigo" outlined @click="saveSlide">
                        <v-icon small class="mr-2">mdi-content-save</v-icon> Enregistrer
                    </v-btn>
                </v-card-actions>

                <!-- Snackbar for Success Message -->
                <v-snackbar :timeout="timeout" :value="saveSuccess" right top color="success" tile>
                    <strong>{{ msgSuccess }}</strong>
                    <template v-slot:action="{ attrs }">
                        <v-btn color="black" text v-bind="attrs" @click="saveSuccess = false">Close</v-btn>
                    </template>
                </v-snackbar>

                <v-snackbar v-model="snackbar" :color="snackbarColor" top right timeout="5000">
                    {{ snackbarMessage }}
                    <v-btn color="white" text @click="snackbar = false">Close</v-btn>
                </v-snackbar>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
import axios from "axios";
let token = localStorage.getItem('token');

export default {
    name: "SlideEditComponent",
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            Url: process.env.VUE_APP_API_URL_STORAGE,
            form: {
                title_fr: "",
                title_ar: "",
                title_en: "",
                img_path: "",
                background: '',
                video_path: "",
                iframe: "",
                link: ""
            },
            backgroundOptions: [],
            snackbar: false,
            msgSuccess: "",
            alert: false,
            saveSuccess: false,
            timeout: 3000,
            imageToDelete: {},
            dialogImageDelete: false,
            overlay: false,
            loading: false,
            snackbarMessage: '', // the error message
            snackbarColor: 'error', // color of the snackbar
        };
    },
    created() {
        this.loadItems();
        this.backgroundOptions = [
            { text: 'Background1', value: 'bg1', image: this.Url + 'images/slides/bg1.jpg' },
            { text: 'Background2', value: 'bg2', image: this.Url + 'images/slides/bg2.jpg' },
            { text: 'Background3', value: 'bg3', image: this.Url + 'images/slides/bg3.jpg' },
            { text: 'Background4', value: 'bg4', image: this.Url + 'images/slides/bg4.jpg' },
            { text: 'Background5', value: 'bg5', image: this.Url + 'images/slides/bg5.jpg' }
        ];
    },
    methods: {
        loadItems() {
            // Check if an ID is present, indicating edit mode
            if (this.id) {
                axios
                    .get(`${this.apiUrl}/slides/${this.id}`, {
                        headers: { Authorization: "Bearer " + token }
                    })
                    .then(response => {
                        this.form = response.data; // Pre-fill form with data from the database
                        this.form.active = this.form.active == 1 ? 1 : 0;

                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },
        saveSlide() {
            this.loading = true;
            const formData = new FormData();
            formData.append("title_fr", this.form.title_fr || ''); // Ensure no field is missing
            formData.append("title_ar", this.form.title_ar || '');
            formData.append("title_en", this.form.title_en || '');
            formData.append("img_path", this.form.img_path || '');
            formData.append("video_path", this.form.video_path || '');
            formData.append("iframe", this.form.iframe || '');
            formData.append("link", this.form.link || '');
            formData.append('background', this.form.background || '');
            formData.append("active", this.form.active ? 1 : 0);

            if (this.form.img_path) {
                formData.append("img_path", this.form.img_path);
            }
            if (this.form.video_path) {
                formData.append("video_path", this.form.video_path);
            }

            // let method = this.id ? "post" : "post";
            let url = this.id
                ? `${this.apiUrl}/slides/${this.id}`
                : `${this.apiUrl}/slides`;

            axios({
                method: "post",
                url: url,
                data: formData,
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(response => {
                    this.saveSuccess = true;
                    this.msgSuccess = "Slide has been saved.";
                })
                .catch((error) => {
                    this.snackbarMessage = error.response.data.message || 'An error occurred while saving the slide.';
                    this.snackbar = true; // Show the snackbar
                    console.log(error.response);
                }).finally(() => {
                    this.loading = false; // Stop loader when action completes
                });
        }

    },
    computed: {
        id() {
            return this.$route.params.id; // Retrieve the ID from the route
        }
    }
};
</script>

<style scoped>
/* You can add your CSS here if needed */
</style>
