<template>

  <v-app>
    <v-main class="container align-center px-1">


      <v-row>
       <v-col cols="12" sm="12"> 
         <h2 class="font-weight-medium mb-2">
        <v-icon >mdi-arrow-right</v-icon>
         {{ editedItem.nom }} 
      </h2>
        <v-btn color="primary" class="float-right" :to="`/Appart_hotels/edit/${id}`" outlined>
        <v-icon >mdi-pencil</v-icon> Modifier </v-btn>
    </v-col>
      </v-row>



      <div>
        <v-alert
            v-model="alert"
            dismissible
            color="green"
            border="left"
            elevation="2"
            colored-border
            type="success"
        >
          <strong>{{ msgSuccess }}</strong>
        </v-alert>
      </div>




        <v-card  style="/* border: 4px dashed #03a9f46e;*/"  >
          <v-card-title>
           <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
            
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.nom" readonly label="Nom"></v-text-field>
              </v-col>
         
                <v-col cols="12" sm="12">
                <v-textarea v-model="editedItem.description" readonly label="Description"></v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.nom_ar" readonly label="Nom"></v-text-field>
              </v-col>
         
                <v-col cols="12" sm="12">
                <v-textarea v-model="editedItem.description_ar" readonly label="Description"></v-textarea>
              </v-col>
              
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.tel" readonly label="Numéro de téléphone "></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.email" readonly label="Email"></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.website" readonly label="Site web"></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.adresse" readonly label="Adresse"></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.instagram" readonly label="Instagram"></v-text-field>
              </v-col>

              
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.whatsapp" readonly label="Whatsapp"></v-text-field>
              </v-col>

                <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.facebook" readonly label="Facebook"></v-text-field>
              </v-col>

                <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.tiktok" readonly label="Tiktok"></v-text-field>
              </v-col>


        <!--
              <v-col cols="12" sm="12">
                <v-file-input
                    v-model="editedItem.image"
                    label="Image"
                    id="fileImage"
                    filled
                    prepend-icon="mdi-camera"
                ></v-file-input>

                        <div class="text-truncate text-center justify-center" :v-for="image in editedItem.images" >
                        <v-img  :src="Url+image.image" :alt="editedItem.nom" width="100px"></v-img>
                        </div>
                   
              </v-col>
        -->

            </v-row>
          </v-card-text>




        </v-card>
       
    <v-card class="mt-3">
          <v-card-title>
           <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
            Images :
          </v-card-title>
              <v-card-text>
                <v-row>
                <v-col cols="2" sm="2" align="left"  v-for="image in editedItem.images" :key="image.id" >
                        <v-img  :src="Url+image.image" :alt="editedItem.nom" style="max-height:100px;margin:auto;"></v-img>
                </v-col>
                </v-row>
          </v-card-text>
    </v-card>    

    <v-card class="mt-3">
          <v-card-title>
           <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
            Services :
          </v-card-title>
              <v-card-text>
                <v-row>
                <v-col cols="3" sm="3" justify-center v-for="service in editedItem.hebergement_service" :key="service.id" >
                  <v-img  :src="Url+service.service.avatar"  style="max-height:50px;margin:auto;max-width:50px;"></v-img>                        
                       <p class="text-center">{{ service.service.nom }}</p>
                </v-col>
                </v-row>
          </v-card-text>
    </v-card>    

    <v-card class="mt-3">
          <v-card-title>
           <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
            Chambres :
          </v-card-title>
              <v-card-text>
                <v-row>
                <v-col cols="12" sm="12"  >
                          <v-simple-table>                            
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      Type
                                    </th>
                                       <th class="text-left">
                                     Options
                                    </th>
                                    <th class="text-left">
                                      Prix
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in editedItem.chambres"
                                    :key="item.id"
                                  >
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.options }}</td>
                                    <td>{{ item.prix }}</td>
                                  </tr>
                                </tbody>
                              </template>
                         </v-simple-table>
                </v-col>
                </v-row>
          </v-card-text>
    </v-card>    
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
let table = '/appart-hotels';

let token = localStorage.getItem('token');
export default {
  name: "ViewAppart_hotelsComponent",
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout')
  },
  data () {
    return {
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nom', value: 'nom' },
        { text: 'Description', value: 'description' },
        { text: 'Image', value: 'image' , sortable: false, width:"100"},
        { text: 'Action', value: 'actions', sortable: false },
      ],
      items: [],
      apiUrl:process.env.VUE_APP_API_URL,
      dialog: false,
      editedItem: {},
      dialogDelete: false,
      itemToDelete: {},
      Url :process.env.VUE_APP_API_URL_STORAGE,
      snackbar: false,
      msgSuccess: [],
      alert: false,
      saveSuccess:false,
      timeout: 3000,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    showEditDialog(item) {
      this.editedItem = item||{}
      this.dialog = !this.dialog
    },
    loadItems() {
      this.items = []

      axios.get(this.apiUrl+table+`/id/${this.id}`,
          { headers: { Authorization: "Bearer " + token }})
          .then((response) => {
            console.warn(response.data);
            this.editedItem = response.data;

          }).catch((error) => {
        console.log(error)
      })
    },
    saveItem(item) {
      /* this is used for both creating and updating API records
       the default method is POST for creating a new item */

      let method = "post"
      let id =   this.editedItem.id
      let url = this.apiUrl+table+`/${id}`;
      
      // airtable API needs the data to be placed in fields object
     /* let data = {
        fields: item
      }
      const object = new FormData ()*/
      //object.append ('nom', data.fields.nom)
      //object.append ('description', data.fields.description)
      //var imagefile = document.querySelector('#fileImage');
      //object.append("image", imagefile.files[0]);

      /*if (id) {
        // if the item has an id, we're updating an existing item
        method = "post"
        url = this.apiUrl+table+`/${id}`

        // must remove id from the data for airtable patch to work
        delete data.fields.id
      }
*/
      // save the record
      axios[method](url,
          this.editedItem,
          { headers: {
              Authorization: "Bearer " + token,
              //'Content-Type': 'multipart/form-data'
              //'Content-Type': imagefile.type
            }
          }).then((response) => {
        

          // add new item to state
          this.saveSuccess = true;
        
      }).catch((error) => {
        this.msgErreur = error;
        console.log(error.response)
      })
    },
    deleteItem() {
      console.log('deleteItem', this.itemToDelete)
      const index = this.items.indexOf(this.itemToDelete)

      axios.delete( this.apiUrl+table+`/${this.itemToDelete.id}`,
          { headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }
          }).then((response) => {
        this.items.splice(index, 1)
        this.dialogDelete = false
        this.alert = true
        this.msgSuccess = 'Le contenu a été supprimé.'
      })


      //this.items.splice(index, 1)
      //this.dialogDelete = false
    },
    showDeleteDialog(item) {
      this.itemToDelete = item
      this.dialogDelete = !this.dialogDelete
    },
  },
  computed: {
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id
    }
  },
}
</script>

<style scoped>

</style>


