<template>
  <v-app>
    <v-main class="container align-center px-1">
      <h2 class="font-weight-medium mb-2">
        <v-icon>mdi-arrow-right</v-icon>
        Lieux à visiter
      </h2>
      <v-card>
        <v-card-title>
          <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.titre" label="Titre"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.titre_ar" label="Titre Arabe"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.titre_en" label="Titre Anglais"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.description" label="Description"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.description_ar" label="Description Arabe"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.description_en" label="Description Anglais"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
            <label for="drpafficher">Ville</label>
              <select class="form-control" v-model="form.ville_id" id="drpafficher">
                <option v-for="ville in villes" :key="ville.id" :value="ville.id"
                  :selected="form.ville_id === ville.id">
                  {{ ville.ville }}
                </option>
              </select>
           </v-col>
            <v-col cols="12" sm="10">
              <v-file-input v-model="form.image" label="La première image" id="fileImage" filled
                prepend-icon="mdi-camera"></v-file-input>
            </v-col>
            <v-col cols="12" sm="2">
              <v-img :src="Url + form.image" :alt="form.nom" style="max-height:100px;max-width:100px;"></v-img>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.seo_titre" label="Titre SEO"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.seo_keywords" label="Mots clés SEO"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.seo_description" label="Description SEO"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.seo_titre_ar" label="Titre SEO(AR)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.seo_keywords_ar" label="Mots clés SEO(AR)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.seo_description_ar" label="Description SEO(AR)"></v-textarea>
            </v-col>  
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.map" label="Map"></v-textarea>
            </v-col>
            <!--
              <v-col cols="12" sm="12">
                <v-file-input
                    v-model="editedItem.image"
                    label="Image"
                    id="fileImage"
                    filled
                    prepend-icon="mdi-camera"
                ></v-file-input>
                        <div class="text-truncate text-center justify-center" :v-for="image in editedItem.images" >
                        <v-img  :src="Url+image.image" :alt="editedItem.nom" width="100px"></v-img>
                        </div>
              </v-col>
        -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12">
            <v-btn color="indigo" outlined @click="saveHeberegement()"><v-icon small
                class="mr-2">mdi-content-save</v-icon> Enregistrer</v-btn>
          </v-col>
        </v-card-actions>
        <v-snackbar :timeout="timeout" :value="saveSuccess" right top color="success" tile app>
          <strong>{{ msgSuccess }}</strong>
          <template v-slot:action="{ attrs }">
            <v-btn color="black" text v-bind="attrs" @click="saveSuccess = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
      <v-card class="mt-3">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="6">
              <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
              Images :
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn color="success" outlined dark class="float-right ma-3" @click="ajouterImage()">
                Ajouter
                <v-icon small>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12">
              <v-file-input label="Image" id="images" filled multiple
                prepend-icon="mdi-camera"></v-file-input>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2" sm="2" align="left" v-for="image in form.images" :key="image.id">
              <v-img :src="Url + image.image" :alt="form.nom" style="max-height:100px;margin:auto;"></v-img>
              <v-btn @click="showDeleteImageDialog(image)" color=""><v-icon color="red">mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- delete dialog image -->
      <v-dialog v-model="dialogImageDelete" max-width="500px">
        <v-card>
          <v-card-title>Delete Image</v-card-title>
          <v-card-text>Voulez-vous vraiment supprimer cet élément ?</v-card-text>
          <v-img :src="Url + imageToDelete.image" style="max-height:100px;max-width:100px;margin:auto;"></v-img>
          <v-card-actions>
            <v-btn color="primary" text @click="dialogImageDelete = false">Close</v-btn>
            <v-btn color="primary" text @click="deleteImage()">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import api from "@/apis/Api";
let table = '/lieux';

let token = localStorage.getItem('token');
export default {
  name: "RiadAddComponent",
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout');
    this.loadItems();
  },
  data() {
    return {

      apiUrl: process.env.VUE_APP_API_URL,
      form: {
        
      },
      Url: process.env.VUE_APP_API_URL_STORAGE,
      snackbar: false,
      msgSuccess: [],
      alert: false,
      saveSuccess: false,
      timeout: 3000,
      imageToDelete: {},
      dialogImageDelete: false,
      overlay: false,
      villes:[],

    }
  },
  mounted() {

  },
  methods: {

    loadItems() {
      this.items = []
      this.villes = []
      axios.get(this.apiUrl + '/villes',
        { headers: { Authorization: "Bearer " + token } })
        .then((response) => {
          this.villes = response.data
        }).catch((error) => {
          console.log(error)
        })
      axios.get(this.apiUrl + table + `/id/${this.id}`,
        { headers: { Authorization: "Bearer " + token } })
        .then((response) => {
          console.warn(response.data);
          this.form = response.data;
        }).catch((error) => {
          console.log(error)
        })
    },
    saveHeberegement(item) {
      /* this is used for both creating and updating API records
       the default method is POST for creating a new item */

      let method = "post"
      //let id =   this.editedItem.id
      let url = this.apiUrl + table + `/${this.id}`;

      // airtable API needs the data to be placed in fields object
      /* let data = {
         fields: item
       }
       const object = new FormData ()*/
      //object.append ('nom', data.fields.nom)
      //object.append ('description', data.fields.description)
      //var imagefile = document.querySelector('#fileImage');
      //object.append("image", imagefile.files[0]);

      /*if (id) {
        // if the item has an id, we're updating an existing item
        method = "post"
        url = this.apiUrl+table+`/${id}`

        // must remove id from the data for airtable patch to work
        delete data.fields.id
      }
*/
      // save the record

      const object = new FormData()
      object.append('titre', this.form.titre)
      object.append('description', this.form.description)
      object.append('titre_ar', this.form.titre_ar)
      object.append('titre_en', this.form.titre_en)
      object.append('description_ar', this.form.description_ar)
      object.append('description_en', this.form.description_en)
      object.append('seo_titre', this.form.seo_titre)
      object.append('seo_description', this.form.seo_description)
      object.append('seo_keywords', this.form.seo_keywords)
      object.append('seo_titre_ar', this.form.seo_titre_ar)
      object.append('seo_description_ar', this.form.seo_description_ar)
      object.append('map', this.form.map)
      object.append('seo_keywords_ar', this.form.seo_keywords_ar)
      object.append ('ville_id', this.form.ville_id)
      // var imagefile = document.querySelector('#fileImage') || null;
      // object.append("image", imagefile.files[0]);
      object.append("image", this.form.image);

      /* var images = document.querySelector('#images');
       for(let i=0; i<images.files.length ;i++){
           object.append("images[]", images.files[i]);
       }
 */
      //console.log(this.editedItem);

      axios[method](url,
        object,
        {
          headers: {
            Authorization: "Bearer " + token,
            //'Content-Type': 'multipart/form-data'
            //'Content-Type': imagefile.type
          }
        }).then((response) => {
          // add new item to state
          this.saveSuccess = true;
          this.msgSuccess = 'Le contenu a été enregistré.';

        }).catch((error) => {
          this.msgErreur = error;
          console.log(error.response)
        })
    },
    showDeleteImageDialog(item) {
      this.imageToDelete = item
      this.dialogImageDelete = !this.dialogImageDelete
    }
    ,
    deleteImage() {
      // console.log('deleteItem', this.imageToDelete)
      // const index = this.items.indexOf(this.imageToDelete)
      axios.delete(this.apiUrl + '/post-images' + `/${this.imageToDelete.id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        }).then((response) => {
          //this.imageToDelete.images.splice(index, 1)
          this.dialogImageDelete = false;
          this.saveSuccess = true;
          this.msgSuccess = 'Le contenu a été supprimé.';
          this.$router.go();
        })
    },
    ajouterImage() {

      this.overlay = true;
      const object = new FormData()
      object.append('id_lieux', this.form.id)
      var imagefile = document.querySelector('#images') || null;


      for (let i = 0; i < imagefile.files.length; i++) {
        object.append("images[]", imagefile.files[i]);
      }

      axios.post(this.apiUrl + '/post-images', object,
        {
          headers: {
            Authorization: "Bearer " + token,
            'Content-Type': imagefile.type
          }
        }).then((response) => {
          this.overlay = false;
            this.msgSuccess = 'Le contenu a été enregistré.';
          this.$router.go();

        }).catch(() => {
          this.overlay = false;
        }).finally(() => {
          this.overlay = false;
        })



    },

  },
  computed: {
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id
    }
  },

}
</script>

<style scoped>

</style>


