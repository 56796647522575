<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Msafar
          </v-list-item-title>
          <v-list-item-subtitle>
            Administration
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <!--indigo-->
      <v-list nav dense rounded>
        <v-list-group color="green" v-for="nav_list in nav_lists" :key="nav_list.title" :prepend-icon="nav_list.icon"
          no-action :append-icon="nav_list.lists ? undefined : ''">
          <template v-slot:activator>
            <v-list-item :to="nav_list.to">
              <v-list-item-content>
                <v-list-item-title>{{ nav_list.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-for="list in nav_list.lists" :key="list.title" :to="list.to">
            <v-list-item-content>
              <v-list-item-title>{{ list.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-item @click.prevent="logout" link>
          <v-list-item-icon>
            <v-icon>{{ 'mdi-logout' }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ 'Déconnexion' }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Application</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click.prevent="logout" icon>
        <v-icon>mdi-export</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer color="black" app>
      <v-spacer></v-spacer>
      <span class="white--text">Msafar &copy; 2022</span>
      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>
<script>
import User from "@/apis/User";
export default {
  data() {
    return {
      right: null,
      drawer: null,
      isLoggedIn: false,
      nav_lists: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/' },
        { title: 'Mon Profile', icon: 'mdi-account-box-outline', to: '/mon-profile' },
        { title: 'Slide', icon: 'mdi-folder-multiple-image', to: '/slides' },
        { title: 'Lieux à visiter', icon: 'mdi-image', to: '/lieux-a-visiter' },
        { title: 'Gastronomie', icon: 'mdi-food-turkey', to: '/gastronomies' },
        { title: 'Evènements', icon: 'mdi-calendar-search', to: '/evenements' },
        //{ title: 'Témoignages', icon: 'mdi-format-list-bulleted', to:'/temoignages' },
        { title: 'Actualités', icon: 'mdi-clipboard-list', to: '/actualites' },
        { title: 'Partages', icon: 'mdi-share-variant-outline', to: '/partages' },
        { title: 'Villes', icon: 'mdi-domain', to: '/villes' },
        {
          icon: 'mdi-home',
          active: true,
          lists: [
            { title: 'Hôtels', icon: 'mdi-view-dashboard', to: '/hotels' },
            { title: 'Appart hôtels', icon: 'mdi-view-dashboard', to: '/Appart_hotels' },
            { title: 'Riads', icon: 'mdi-view-dashboard', to: '/riads' },
            { title: 'Restaurants', icon: 'mdi-silverware', to: '/restaurants' },
            { title: 'Maisons d\'hôtes', icon: 'mdi-view-dashboard', to: '/maisons-hotes' },
            { title: 'Produits du terroir', icon: 'mdi-nature-people', to: '/guides-touristiques' },
            { title: 'Activités Touristiques', icon: 'mdi-signature-image', to: '/activites-touristiques' },
          ],
          title: 'Hébergements',
        },
        { title: 'Comptes professionnels', icon: 'mdi-account-multiple-outline', to: '/comptes-professionnels' },
        { title: 'Comptes Voyageurs', icon: 'mdi-account-outline', to: '/comptes-voyageurs' },
        { title: 'Commentaires Partage ...', icon: 'mdi-comment-text-outline', to: '/commentaires-partages' },
        { title: 'Commentaires Hôtel ...', icon: 'mdi-comment-processing-outline', to: '/commentaires' },
      ],
    };
  },
  mounted() {
    console.log(process.env.VUE_APP_API_URL);
    this.$root.$on("login", () => {
      this.isLoggedIn = true;
    });
    this.isLoggedIn = !!localStorage.getItem('token');
  },
  methods: {
    logout() {
      User.logout().then(() => {
        sessionStorage.removeItem('token');
        // localStorage.removeItem('token');
        this.isLoggedIn = false;
        this.$router.push({ name: "login" });
        this.$router.go();
      })
        .catch(() => {
          sessionStorage.clear();
          // localStorage.clear();
          // this.$toaster.success('Vous vous êtes déconnecté avec succès.')
          this.$router.push({ name: "login" });
          this.$router.go();
        }).finally(() => {
          sessionStorage.clear();
          // localStorage.clear();
        });
    },
  }
}
</script>