<template>

  <v-app>
    <v-main class="container align-center px-1">
      <h2 class="font-weight-medium mb-2">
        <v-icon >mdi-arrow-right</v-icon>
        Mon profile
      </h2>
     



        <v-card>
          <v-card-title>
           <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
            
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field v-model="form.nom" label="Nom"></v-text-field>
              </v-col>

             <v-col cols="12" sm="12">
                <v-text-field v-model="form.prenom" label="Prénom"></v-text-field>
              </v-col>

               <v-col cols="12" sm="12">
                <v-text-field v-model="form.email" label="Adresse e-mail"></v-text-field>
              </v-col>

              <!-- <v-col cols="12" sm="12">
                <v-text-field v-model="form.password" label="Mot de passe"></v-text-field>
              </v-col>-->


  <v-col  cols="12" sm="12"    >
          <v-text-field
            v-model="form.password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"            
            label="Mot de passe"
            hint="Au moins 8 caractères"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
    </v-col>

              

            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

              <v-col cols="12" sm="12">
            <v-btn color="indigo" outlined  @click="update()"><v-icon small class="mr-2">mdi-content-save</v-icon> Enregistrer</v-btn>

      
              </v-col>
          </v-card-actions>
</v-card>
   <v-snackbar
          :timeout="timeout"
          :value="saveSuccess"
          right
          top 
          color="success"
          tile
          app
          
        >
          <strong>{{ msgSuccess }}</strong> 

            <template v-slot:action="{ attrs }">
              <v-btn
                color="black"
                text
                v-bind="attrs"
                @click="saveSuccess = false"
              >
                Close
              </v-btn>
            </template>
        </v-snackbar>


 

        <v-overlay :value="overlay">
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular>
    </v-overlay>


    </v-main>
  </v-app>
</template>

<script>
//import axios from "axios";
//import api from "@/apis/Api";
import User from "@/apis/User";



//let token = localStorage.getItem('token');
export default {
  name: "EditUserComponent",
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout');
  },
  data () {
    return {
     
      apiUrl:process.env.VUE_APP_API_URL,
      form: {
              
      },
      Url :process.env.VUE_APP_API_URL_STORAGE,
      snackbar: false,
      msgSuccess: [],
      alert: false,
      saveSuccess:false,
      timeout: 3000,
      imageToDelete:{},
      dialogImageDelete:false,
      overlay:false,
    show1: false,
    password: 'Password',
    rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
    },
    }
  },
   mounted(){
      this.getUser();
  },
  methods: {
    getUser(){
          User.auth().then((response) => {
              console.log(response.data);
              this.form = response.data;
              this.form.confirmation_de_adresse_email = response.data.email;
              this.form.confirmation_de_mot_de_passe = response.data.password;
          }).catch(error =>{
              console.log(error);
          });
    },
    update() {
      User.updateprofil(this.form)
        .then(() => {
          //this.form = response.data    
          //this.$set( "user",response.data)
            this.saveSuccess = true;
            this.msgSuccess = 'Le contenu a été enregistré.';
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    }
  }
}
</script>
<style scoped>



</style>

