<template>
  <v-app>
    <v-main class="container align-center px-1">
      <h2 class="font-weight-medium mb-2">
        <v-icon>mdi-arrow-right</v-icon>
        Actualité
      </h2>
      <v-card>
        <v-card-title>
          <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.titre" label="Titre"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.titre_ar" label="Titre Arabe"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
                <v-text-field v-model="form.titre_en" label="Titre Anglais"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.description" label="Description"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.description_ar" label="Description Arabe"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
                <v-textarea v-model="form.description_en" label="Description Anglais"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
            <label for="drpafficher">Ville</label>
              <select class="form-control" v-model="form.ville_id" id="drpafficher">
                <option v-for="ville in villes" :key="ville.id" :value="ville.id"
                  :selected="form.ville_id === ville.id">
                  {{ ville.ville }}
                </option>
              </select>
           </v-col>
            <v-col cols="12" sm="10">
              <v-file-input v-model="form.image" label="La première image" id="fileImage" filled
                prepend-icon="mdi-camera"></v-file-input>
            </v-col>
            <v-col cols="12" sm="2">
              <v-img :src="Url + form.image" :alt="form.nom" style="max-height:100px;max-width:100px;"></v-img>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.seo_titre" label="Titre SEO"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.seo_keywords" label="Mots clés SEO"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.seo_description" label="Description SEO"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.seo_titre_ar" label="Titre SEO(AR)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="form.seo_keywords_ar" label="Mots clés SEO(AR)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="form.seo_description_ar" label="Description SEO(AR)"></v-textarea>
            </v-col>
            <!--
              <v-col cols="12" sm="12">
                <v-file-input
                    v-model="editedItem.image"
                    label="Image"
                    id="fileImage"
                    filled
                    prepend-icon="mdi-camera"
                ></v-file-input>
                        <div class="text-truncate text-center justify-center" :v-for="image in editedItem.images" >
                        <v-img  :src="Url+image.image" :alt="editedItem.nom" width="100px"></v-img>
                        </div>
              </v-col>
        -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12">
            <v-btn color="indigo" outlined @click="saveHeberegement()"><v-icon small
                class="mr-2">mdi-content-save</v-icon> Enregistrer</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-snackbar :timeout="timeout" :value="saveSuccess" right top color="success" tile app>
        <strong>{{ msgSuccess }}</strong>
        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs" @click="saveSuccess = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-main>
  </v-app>
</template>
<script>
import axios from "axios";
import api from "@/apis/Api";
let table = '/actualites';
let token = localStorage.getItem('token');
export default {
  name: "EditActualiteComponent",
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout');
    this.loadItems();
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      form: {
      },
      Url: process.env.VUE_APP_API_URL_STORAGE,
      snackbar: false,
      msgSuccess: [],
      alert: false,
      saveSuccess: false,
      timeout: 3000,
      imageToDelete: {},
      dialogImageDelete: false,
      overlay: false,
      villes:[],
    }
  },
  mounted() {
  },
  methods: {
    loadItems() {
      this.items = []
      this.villes = []
      axios.get(this.apiUrl + '/villes',
        { headers: { Authorization: "Bearer " + token } })
        .then((response) => {
          this.villes = response.data
        }).catch((error) => {
          console.log(error)
        })
      axios.get(this.apiUrl + table + `/id/${this.id}`,
        { headers: { Authorization: "Bearer " + token } })
        .then((response) => {
          console.warn(response.data);
          this.form = response.data;
        }).catch((error) => {
          console.log(error)
        })
    },
    saveHeberegement(item) {
      /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
      let method = "post"
      //let id =   this.editedItem.id
      let url = this.apiUrl + table + `/${this.id}`;
      // airtable API needs the data to be placed in fields object
      /* let data = {
         fields: item
       }
       const object = new FormData ()*/
      //object.append ('nom', data.fields.nom)
      //object.append ('description', data.fields.description)
      //var imagefile = document.querySelector('#fileImage');
      //object.append("image", imagefile.files[0]);
      /*if (id) {
        // if the item has an id, we're updating an existing item
        method = "post"
        url = this.apiUrl+table+`/${id}`
        // must remove id from the data for airtable patch to work
        delete data.fields.id
      }
*/
      // save the record
      const object = new FormData()
      object.append('titre', this.form.titre)
      object.append('description', this.form.description)
      object.append('titre_ar', this.form.titre_ar)
      object.append('titre_en', this.form.titre_en)
      object.append('description_ar', this.form.description_ar)
      object.append('description_en', this.form.description_en)
      var imagefile = document.querySelector('#fileImage');
      object.append('seo_titre', this.form.seo_titre)
      object.append('seo_description', this.form.seo_description)
      object.append('seo_keywords', this.form.seo_keywords)
      object.append('seo_titre_ar', this.form.seo_titre_ar)
      object.append('seo_description_ar', this.form.seo_description_ar)
      object.append('seo_keywords_ar', this.form.seo_keywords_ar)
      object.append ('ville_id', this.form.ville_id)
      // object.append("image", imagefile.files[0]);
      object.append("image", this.form.image);
      /* var images = document.querySelector('#images');
       for(let i=0; i<images.files.length ;i++){
           object.append("images[]", images.files[i]);
       }
 */
      //console.log(this.editedItem);
      axios[method](url,
        object,
        {
          headers: {
            Authorization: "Bearer " + token,
            //'Content-Type': 'multipart/form-data'
            //'Content-Type': imagefile.type
          }
        }).then((response) => {
          // add new item to state
          this.saveSuccess = true;
          this.msgSuccess = 'Le contenu a été enregistré.';
        }).catch((error) => {
          this.msgErreur = error;
          console.log(error.response)
        })
    },
  },
  computed: {
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id
    }
  },
}
</script>
<style scoped></style>
