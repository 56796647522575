<template>
  <v-app>
    <v-main class="container align-center px-1">
      <h2 class="font-weight-medium mb-2">
        <v-icon>mdi-arrow-right</v-icon>
        Appart Hotels
      </h2>
      <div>
        <v-alert v-model="alert" dismissible color="green" border="left" elevation="2" colored-border type="success">
          <strong>{{ msgSuccess }}</strong>
        </v-alert>
      </div>
      <v-card>
        <v-card-title>
          <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <label for="drpafficher">Afficher</label>
              <select class="form-control" v-model="editedItem.display" id="drpafficher">
                <option value="1">Oui</option>
                <option value="0">No</option>
              </select>
            </v-col>
            <v-col cols="12" sm="12">
              <label for="drpafficher">Afficher sur la Page d'Accueil</label>
              <select class="form-control" v-model="editedItem.display_home" id="drpafficher">
                <option value="1">Oui</option>
                <option value="0">No</option>
              </select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.nom" label="Nom"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.nom_ar" label="Nom Arabe"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.nom_en" label="Nom Anglais"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="editedItem.description" label="Description"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="editedItem.description_ar" label="Description Arabe"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="editedItem.description_en" label="Description Anglais"></v-textarea>
            </v-col>
            <v-col cols="12" sm="12">
              <label for="drpafficher">Ville</label>
              <select class="form-control" v-model="editedItem.ville_id" id="drpafficher">
                <option v-for="ville in villes" :key="ville.id" :value="ville.id">
                  {{ ville.ville }}
                </option>
              </select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.tel" label="Numéro de téléphone "></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.website" label="Site web"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.adresse" label="Adresse"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.adresse_ar" label="Adresse(AR)"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.map" label="Map"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.instagram" label="Instagram"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.whatsapp" label="Whatsapp"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.facebook" label="Facebook"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.tiktok" label="Tiktok"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field v-model="editedItem.meta_titre" label="Meta titre (FR)"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field v-model="editedItem.meta_titre_ar" label="Meta titre (AR)"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field v-model="editedItem.meta_description" label="Meta description (FR)"></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field v-model="editedItem.meta_description_ar" label="Meta description (AR)"></v-text-field>
            </v-col>
            <!--
              <v-col cols="12" sm="12">
                <v-file-input
                    v-model="editedItem.image"
                    label="Image  "
                    id="fileImage"
                    filled
                    prepend-icon="mdi-camera"
                ></v-file-input>
                        <div class="text-truncate text-center justify-center" :v-for="image in editedItem.images" >
                        <v-img  :src="Url+image.image" :alt="editedItem.nom" width="100px"></v-img>
                        </div>

              </v-col>
        -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12">
            <v-btn color="indigo" outlined @click="saveHeberegement()"><v-icon small
                class="mr-2">mdi-content-save</v-icon> Enregistrer</v-btn>
          </v-col>
        </v-card-actions>
        <v-snackbar :timeout="timeout" :value="saveSuccess" right top color="success" tile app>
          <strong>{{ msgSuccess }}</strong>
          <template v-slot:action="{ attrs }">
            <v-btn color="black" text v-bind="attrs" @click="saveSuccess = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-main>
  </v-app>
</template>
<script>
import axios from "axios";
import api from "@/apis/Api";
import User from "@/apis/User";
let table = '/appart-hotels';
let token = localStorage.getItem('token');
export default {
  name: "Appart_hôtelsAddComponent",
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout');
    this.getUser();
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      editedItem: {
        nom: '',
        description: '',
        nom_ar: '',
        nom_en: '',
        description_ar: '',
        description_en: '',
        nbr_etoiles: '',
        tel: '',
        email: '',
        website: '',
        display_home: 0,
        display: 1,
        adresse: '',
        adresse_ar: '',
        map: '',
        instgram: '',
        facebook: '',
        tiktok: '',
        whatsapp: '',
      },
      Url: process.env.VUE_APP_API_URL_STORAGE,
      snackbar: false,
      msgSuccess: [],
      alert: false,
      saveSuccess: false,
      timeout: 3000,
      user: {},
      villes: [],
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    getUser() {
      User.auth().then((response) => {
        this.user = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    loadItems() {
      this.villes = []
      axios.get(this.apiUrl + '/villes',
        { headers: { Authorization: "Bearer " + token } })
        .then((response) => {
          this.villes = response.data
          console.warn(this.villes);
        }).catch((error) => {
          console.log(error)
        })
    },
    saveHeberegement(item) {
      /* this is used for both creating and updating API records
       the default method is POST for creating a new item */
      let method = "post"
      //let id =   this.editedItem.id
      let url = this.apiUrl + table;//+`/${id}`;
      // airtable API needs the data to be placed in fields object
      /* let data = {
         fields: item
       }
       const object = new FormData ()*/
      //object.append ('nom', data.fields.nom)
      //object.append ('description', data.fields.description)
      //var imagefile = document.querySelector('#fileImage');
      //object.append("image", imagefile.files[0]);
      /*if (id) {
        // if the item has an id, we're updating an existing item
        method = "post"
        url = this.apiUrl+table+`/${id}`
        // must remove id from the data for airtable patch to work
        delete data.fields.id
      }
*/
      // save the record
      this.editedItem.user_id = this.user.id;
      axios[method](url,
        this.editedItem,
        {
          headers: {
            Authorization: "Bearer " + token,
            //'Content-Type': 'multipart/form-data'
            //'Content-Type': imagefile.type
          }
        }).then((response) => {
          // add new item to state
          this.saveSuccess = true;
          this.msgSuccess = 'Le contenu a été enregistré.';
          setTimeout(() => this.$router.push({ path: '/Appart_hotels/edit/' + response.data.id }), 3000);
        }).catch((error) => {
          this.msgErreur = error;
          console.log(error.response)
        })
    },
  },
}
</script>
<style scoped>
#drpafficher {
  width: 100%;
  padding: 7px;
  border: 1px solid;
  margin-top: 10px;
}
</style>