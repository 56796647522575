
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Evenements from "@/components/Evenements";
import Gastronomies from "@/components/Gastronomies";

//Acualites
import Actualites from "@/components/Actualites";
import AddActualite from "@/components/Actualites/AddActualite";
import EditActualite from "@/components/Actualites/EditActualite";

import Commentaire from "@/components/Commentaire";
import CommentairePartage from "@/components/CommentairePartage";

// lieux à visiter
import LieuxVisiter from "@/components/LieuxVisiter";
import AddLieux from "@/components/Lieux/AddLieux";
import EditLieux from "@/components/Lieux/EditLieux";

import Temoignages from "@/components/Temoignages";
// Hotels
import Hotels from "@/components/Hebergements/Hotels";
import EditHotel from "@/components/Hebergements/Hotels/EditHotel";
import AddHotel from "@/components/Hebergements/Hotels/AddHotel";
import ViewHotel from "@/components/Hebergements/Hotels/ViewHotel";
// Riads
import Riads from "@/components/Hebergements/Riads";
import EditRiad from "@/components/Hebergements/Riads/EditRiad";
import AddRiad from "@/components/Hebergements/Riads/AddRiad";
import ViewRiad from "@/components/Hebergements/Riads/ViewRiad";
// Maisons Hôtes
import MaisonsHotes from "@/components/Hebergements/MaisonsHotes";
import EditMaisonHote from "@/components/Hebergements/MaisonsHotes/EditMaisonHote";
import AddMaisonHote from "@/components/Hebergements/MaisonsHotes/AddMaisonHote";
import ViewMaisonHote from "@/components/Hebergements/MaisonsHotes/ViewMaisonHote";
// Restaurants
import Restaurants from "@/components/Hebergements/Restaurants";
import EditRestaurant from "@/components/Hebergements/Restaurants/EditRestaurant";
import AddRestaurant from "@/components/Hebergements/Restaurants/AddRestaurant";
import ViewRestaurant from "@/components/Hebergements/Restaurants/ViewRestaurant";

// Activites Touristiques
import ActivitesTouristiques from "@/components/Hebergements/ActivitesTouristiques";
import EditActivite from "@/components/Hebergements/ActiviteTouristique/EditActivite";
import AddActivite from "@/components/Hebergements/ActiviteTouristique/AddActivite";
import ViewActivite from "@/components/Hebergements/ActiviteTouristique/ViewActivite";

// Guides Touristiques
import GuidesTouristiques from "@/components/Hebergements/GuidesTouristiques";
import EditGuide from "@/components/Hebergements/GuideTouristique/EditGuide";
import AddGuide from "@/components/Hebergements/GuideTouristique/AddGuide";
import ViewGuide from "@/components/Hebergements/GuideTouristique/ViewGuide";


import ComptesProfessionnels from "@/components/ComptesProfessionels";
import ComptesVoyageurs from "@/components/ComptesVoyageurs";
import Partages from "@/components/Partages";
import Login from "@/components/Auth/Login";
import MonProfile from "@/components/Auth/MonProfile";

// Appart_hotels
import Appart_hotels from "@/components/Hebergements/Appart_hotels";
import EditAppart_hotel from "@/components/Hebergements/Appart_hotels/EditAppart_hotel";
import AddAppart_hotel from "@/components/Hebergements/Appart_hotels/AddAppart_hotel";
import ViewAppart_hotel from "@/components/Hebergements/Appart_hotels/ViewAppart_hotel";

// Slides
import Slides from "@/components/Hebergements/slides/Slides"
import EditSlide from "@/components/Hebergements/slides/EditSlide"
import AddSlide from "@/components/Hebergements/slides/AddSlide"

// Ville
import AddVille from "@/components/villes/AddVille.vue";
import EditVille from "@/components/villes/EditVille.vue";
import Villes from "@/components/villes/Villes.vue"




Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: HomeView,
        meta: { authOnly: true },
    },
    {
        path: '/index.html',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: "/mon-profile",
        name: "MonProfile",
        component: MonProfile,
        meta: { authOnly: true },
    },
    {
        path: "/evenements",
        name: "Evenements",
        component: Evenements,
        meta: { authOnly: true },
    },
    {
        path: "/gastronomies",
        name: "Gastronomies",
        component: Gastronomies,
        meta: { authOnly: true },
    },
    {
        path: "/actualites",
        name: "Actualites",
        component: Actualites,
        meta: { authOnly: true },
    },
    {
        path: "/actualites/add",
        name: "AddActualite",
        component: AddActualite,
        meta: { authOnly: true },
    },
    {
        path: "/actualites/edit/:id",
        name: "EditActualite",
        component: EditActualite,
        meta: { authOnly: true },
    },
    {
        path: "/commentaires",
        name: "Commentaire",
        component: Commentaire,
        meta: { authOnly: true },
    },
    {
        path: "/commentaires-partages",
        name: "CommentairePartage",
        component: CommentairePartage,
        meta: { authOnly: true },
    },
    {
        path: "/villes",
        name: "Villes",
        component: Villes,
        meta: { authOnly: true },
    },
    {
        path: "/slides",
        name: "Slides",
        component: Slides,
        meta: { authOnly: true },
    },
    {
        path: "/slides/edit/:id",
        name: "EditSlide",
        component: EditSlide,
        meta: { authOnly: true },
    },
    {
        path: "/slides/add",
        name: "AddSlide",
        component: AddSlide,
        meta: { authOnly: true },
    },
    {
        path: "/villes/edit/:id",
        name: "EditVille",
        component: EditVille,
        meta: { authOnly: true },
    },
    {
        path: "/villes/add",
        name: "Addville",
        component: AddVille,
        meta: { authOnly: true },
    },
    {
        path: "/lieux/add",
        name: "AddLieux",
        component: AddLieux,
        meta: { authOnly: true },
    },
    {
        path: "/lieux/edit/:id",
        name: "EditLieux",
        component: EditLieux,
        meta: { authOnly: true },
    },
    {
        path: "/lieux-a-visiter",
        name: "LieuxVisiter",
        component: LieuxVisiter,
        meta: { authOnly: true },
    },
    {
        path: "/temoignages",
        name: "Temoignages",
        component: Temoignages,
        meta: { authOnly: true },
    },
    {
        path: "/partages",
        name: "Partages",
        component: Partages,
        meta: { authOnly: true },
    },
    {
        path: "/hotels",
        name: "Hotels",
        component: Hotels,
        meta: { authOnly: true },
    },
    {
        path: "/hotels/edit/:id",
        name: "EditHotel",
        component: EditHotel,
        meta: { authOnly: true },
    },
    {
        path: "/hotels/view/:id",
        name: "ViewHotel",
        component: ViewHotel,
        meta: { authOnly: true },
    },
    {
        path: "/hotels/add",
        name: "AddHotel",
        component: AddHotel,
        meta: { authOnly: true },
    },
    {
        path: "/riads",
        name: "Riads",
        component: Riads,
        meta: { authOnly: true },
    },
    {
        path: "/riads/add",
        name: "addRiad",
        component: AddRiad,
        meta: { authOnly: true },
    },
    {
        path: "/riads/edit/:id",
        name: "EditRiad",
        component: EditRiad,
        meta: { authOnly: true },
    },
    {
        path: "/riads/view/:id",
        name: "ViewRiad",
        component: ViewRiad,
        meta: { authOnly: true },
    },
    {
        path: "/AddAppart_hotels/add",
        name: "AddAppart_hotel",
        component: AddAppart_hotel,
        meta: { authOnly: true },
    },
    {
        path: "/Appart_hotels",
        name: "Appart_hotels",
        component: Appart_hotels,
        meta: { authOnly: true },
    },
    {
        path: "/Appart_hotels/edit/:id",
        name: "EditAppart_hotel",
        component: EditAppart_hotel,
        meta: { authOnly: true },
    },
    {
        path: "/Appart_hotels/view/:id",
        name: "ViewAppart_hotel",
        component: ViewAppart_hotel,
        meta: { authOnly: true },
    },
    {
        path: "/maisons-hotes",
        name: "MaisonsHotes",
        component: MaisonsHotes,
        meta: { authOnly: true },
    },
    {
        path: "/maisons-hotes/edit/:id",
        name: "EditMaisonHote",
        component: EditMaisonHote,
        meta: { authOnly: true },
    },
    {
        path: "/maisons-hotes/view/:id",
        name: "ViewMaisonHote",
        component: ViewMaisonHote,
        meta: { authOnly: true },
    },
    {
        path: "/maisons-hotes/add",
        name: "AddMaisonHote",
        component: AddMaisonHote,
        meta: { authOnly: true },
    },
    {
        path: "/restaurants",
        name: "Restaurants",
        component: Restaurants,
        meta: { authOnly: true },
    },
    {
        path: "/restaurants/view/:id",
        name: "ViewRestaurant",
        component: ViewRestaurant,
        meta: { authOnly: true },
    },
    {
        path: "/restaurants/edit/:id",
        name: "EditRestaurant",
        component: EditRestaurant,
        meta: { authOnly: true },
    },
    {
        path: "/restaurants/add",
        name: "AddRestaurant",
        component: AddRestaurant,
        meta: { authOnly: true },
    },
    {
        path: "/activites-touristiques",
        name: "ActivitesTouristiques",
        component: ActivitesTouristiques,
        meta: { authOnly: true },
    },
    {
        path: "/activites-touristiques/edit/:id",
        name: "EditActivite",
        component: EditActivite,
        meta: { authOnly: true },
    },
    {
        path: "/activites-touristiques/view/:id",
        name: "ViewActivite",
        component: ViewActivite,
        meta: { authOnly: true },
    },
    {
        path: "/activites-touristiques/add",
        name: "AddActivite",
        component: AddActivite,
        meta: { authOnly: true },
    },
    {
        path: "/guides-touristiques",
        name: "GuidesTouristiques",
        component: GuidesTouristiques,
        meta: { authOnly: true },
    },
    {
        path: "/guides-touristiques/edit/:id",
        name: "EditGuide",
        component: EditGuide,
        meta: { authOnly: true },
    },
    {
        path: "/guides-touristiques/view/:id",
        name: "ViewGuide",
        component: ViewGuide,
        meta: { authOnly: true },
    },
    {
        path: "/guides-touristiques/add",
        name: "AddGuide",
        component: AddGuide,
        meta: { authOnly: true },
    },
    {
        path: "/comptes-professionnels",
        name: "ComptesProfessionnels",
        component: ComptesProfessionnels,
        meta: { authOnly: true },
    },
    {
        path: "/comptes-voyageurs",
        name: "ComptesVoyageurs",
        component: ComptesVoyageurs,
        meta: { authOnly: true },
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


function isLoggedIn() {
    return sessionStorage.getItem("token");
}
// function logout() {
//     localStorage.removeItem('token');
//     localStorage.clear();
//   }
// setTimeout(logout, 60 * 60 * 1000);
router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.authOnly)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!isLoggedIn()) {
            next({
                path: "/login",
                query: { redirect: to.fullPath },
            });
        } else {
            next();

        }
    } else if (to.matched.some((record) => record.meta.guestOnly)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (isLoggedIn()) {
            next({
                path: "/",
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});



/*
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!auth.loggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
*/
export default router