<template>

  <v-app>
    <v-main class="container align-center px-1">
      <h2 class="font-weight-medium mb-2">
        <v-icon >mdi-arrow-right</v-icon>
        Comptes Professionnels
      </h2>
      <div>
        <v-alert
            v-model="alert"
            dismissible
            color="green"
            border="left"
            elevation="2"
            colored-border
            type="success"
        >
          <strong>{{ msgSuccess }}</strong>
        </v-alert>
      </div>

      <v-dialog v-model="dialog" max-width="500px">




        <template v-slot:activator="{ on }">
          <div class="d-flex">
           <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
              New
              <v-icon small>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </template>
      
        <v-card>
          <v-card-title>
            <span v-if="editedItem.id">Edit {{editedItem.id}}</span>
            <span v-else>Create</span>
          </v-card-title>
          <v-card-text>
            <v-row>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.nom" label="Nom"></v-text-field>
              </v-col>
              
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.prenom" label="Prénom"></v-text-field>
              </v-col>

            <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.email" label="e-Mail"></v-text-field>
              </v-col>

               <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.password" label="Mot de passe"></v-text-field>
              </v-col>

                  <v-col
                      class="d-flex"
                      cols="12"
                      sm="12"
                    >
                      <v-select
                        v-model="editedItem.type_service"
                        :items="options"
                        item-text="text"
                        item-value="value"
                        label="Type Service"
                      ></v-select>
                    </v-col>

              <!--<v-col cols="12" sm="12">
                <v-file-input
                    v-model="editedItem.image"
                    label="Image"
                    id="fileImage"
                    filled
                    prepend-icon="mdi-camera"
                ></v-file-input>
              </v-col>-->



            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="blue darken-1" text @click="showEditDialog()">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="saveItem(editedItem)">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card>

          <v-card-title>
              
                <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            mobile-breakpoint="800"
            class="elevation-0">
          <template v-slot:[`item.actions`]="{ item }">
            <div class="btn-actions">
              <!--
              <v-btn :to="'/hotels/view/'+item.id">
                <v-icon small color="info"> mdi-eye</v-icon>
              </v-btn>

              <v-btn :to="'/hotels/edit/'+item.id">
                <v-icon small color="success"> mdi-pencil</v-icon>
              </v-btn>
             -->

              <v-icon
                  small
                  class="mr-2"
                  @click="showEditDialog(item)"
                  color="primary"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                  small
                  @click="showDeleteDialog(item)"
                  color="pink"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.nom`]="{ item }">
            <div class="text-truncate" style="width: 180px">
              {{item.nom}}
            </div>
          </template>
          <template v-slot:[`item.prenom`]="{ item }">
            <div class="text-truncate" style="width: 180px">
              {{item.prenom}}
            </div>
          </template>
           <template v-slot:[`item.email`]="{ item }">
            <div class="text-truncate" style="width: 180px">
              {{item.email}}
            </div>
          </template>
           <template v-slot:[`item.type_service`]="{ item }">
            <div class="text-truncate" >
           <!--  <v-btn color='primary' v-if="item.hotels_id !== null" :to="`/hotels/view/${item.hotels_id}`"> {{item.type_service}}</v-btn>
            --> <v-btn  > {{item.type_service}}</v-btn>
            </div>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <div class="text-truncate" style="width: 180px">
              {{ item.created_at | formatDate }}
            </div>
          </template>

        </v-data-table>
        <!-- delete dialog -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title>Delete</v-card-title>
            <v-card-text>Voulez-vous vraiment supprimer cet élément  <strong> `{{itemToDelete.nom+' '+itemToDelete.prenom}}` </strong> ?</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="dialogDelete = false">Close</v-btn>
              <v-btn color="primary" text @click="deleteItem()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- this dialog is used for both create and update -->

                   <v-snackbar
          :timeout="timeout"
          :value="saveSuccess"
          right
          top 
          color="success"
          tile
          app
          
        >
          <strong>{{ msgSuccess }}</strong> 

            <template v-slot:action="{ attrs }">
              <v-btn
                color="black"
                text
                v-bind="attrs"
                @click="saveSuccess = false"
              >
                Close
              </v-btn>
            </template>
        </v-snackbar>


      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
let table = '/users/professionnels';

let token = localStorage.getItem('token');
export default {
  name: "ComptesProfessionelComponent",
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout')
  },
  data () {
    return {
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nom', value: 'nom' },
        { text: 'Prénom', value: 'prenom' },   
        { text: 'e-Mail', value: 'email' },   
        { text: 'Type', value: 'type_service' },
        { text: 'Date création', value: 'created_at' },
        { text: 'Action', value: 'actions', sortable: false },
      ],
      options: [
        { text: 'Hôtels', value: 'hotel' },
        { text: 'Riads', value: 'riad' },
        { text: 'Maisons d\'hôtes', value: 'maison-hote' },
        { text: 'Restaurants', value: 'restaurant' },
        { text: 'Activités touristiques', value: 'activite' },
        { text: 'Guides touristiques', value: 'guide' }
      ],
      items: [],
      apiUrl:process.env.VUE_APP_API_URL,
      dialog: false,
      editedItem: {},
      dialogDelete: false,
      itemToDelete: {},
      Url :process.env.VUE_APP_API_URL_STORAGE,
     snackbar: false,
      msgSuccess: [],
       saveSuccess:false,
      timeout: 3000,
      alert: false,
      search:'',
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    showEditDialog(item) {
      this.editedItem = item||{}
      this.dialog = !this.dialog
    },
    loadItems() {
      this.items = []

      axios.get(this.apiUrl+table,
          { headers: { Authorization: "Bearer " + token }})
          .then((response) => {
            console.warn(response.data);
            this.items = response.data.map((item)=>{                
              return {
                id: item.id,
                nom: item.nom,
                prenom: item.prenom,
                created_at: item.created_at,
                type_service: item.type_service,
                email: item.email,
                password: '',
                //hotels_id:item.hotels_id,
               
                ...item.fields
              }
            })

          }).catch((error) => {
        console.log(error)
      })
    },
    saveItem(item) {
      /* this is used for both creating and updating API records
       the default method is POST for creating a new item */

      let method = "post"
      let url = this.apiUrl+'/admin/register'
      let id = item.id
      // airtable API needs the data to be placed in fields object
      let data = {
        fields: item
      }
      const object = new FormData ()
      object.append ('nom', data.fields.nom)
      object.append ('prenom', data.fields.prenom)
      object.append ('email', data.fields.email)
      object.append ('password', data.fields.password)
      object.append ('type_service', data.fields.type_service)
      object.append ('role_id', 2)
      /*var imagefile = document.querySelector('#fileImage');
      object.append("image", imagefile.files[0]);*/

      if (id) {
        // if the item has an id, we're updating an existing item
        method = "post"
        url = this.apiUrl+`/users/update/${id}`

        // must remove id from the data for airtable patch to work
       // delete data.fields.id
      }

      // save the record
      axios[method](url,
          object,
          { headers: {
              Authorization: "Bearer " + token,
              //'Content-Type': 'multipart/form-data'
              //'Content-Type': imagefile.type
            }
          }).then((response) => {
        if (response.data && response.data.id) {

          // add new item to state
          this.editedItem.id = response.data.id
          if (!id) {
            // add the new item to items state
            this.items.push(this.response.data)
          }

          this.editedItem = {}
        }
        this.dialog = !this.dialog
        //console.log('Le contenu a été enregistré')
        this.alert = true
        this.msgSuccess = 'Le contenu a été enregistré.'
        this.loadItems();
      }).catch((error) => {
        this.msgErreur = error;
        console.log(error.response)
      })
    },
    deleteItem() {
      console.log('deleteItem', this.itemToDelete)
      const index = this.items.indexOf(this.itemToDelete)

      axios.delete( this.apiUrl+'/users'+`/${this.itemToDelete.id}`,
          { headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }
          }).then((response) => {
        this.items.splice(index, 1)
        this.dialogDelete = false
        this.alert = true
        this.msgSuccess = 'Le contenu a été supprimé.'
      })


      //this.items.splice(index, 1)
      //this.dialogDelete = false
    },
    showDeleteDialog(item) {
      this.itemToDelete = item
      this.dialogDelete = !this.dialogDelete
    },
  }
}
</script>

<style scoped>

</style>


