<template>
    <v-app>
        <v-main class="container align-center px-1">
            <h2 class="font-weight-medium mb-2">
                <v-icon>mdi-arrow-right</v-icon>
                Gestion des slides
            </h2>
            <v-card>
                <v-card-title>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="form.title_fr" label="Titre Français"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="form.title_ar" label="Titre Arabe"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="form.title_en" label="Titre Anglais"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <v-file-input v-model="form.img_path" label="Chemin de l'image" id="fileImage" filled
                                prepend-icon="mdi-camera"></v-file-input>
                        </v-col>
                        <v-col cols="12" sm="10">
                            <v-file-input v-model="form.video_path" label="Chemin de la vidéo" id="fileVideo" filled
                                prepend-icon="mdi-video"></v-file-input>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-textarea v-model="form.iframe" label="Iframe"></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-text-field v-model="form.link" label="Lien"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                            <v-select v-model="form.background" :items="backgroundOptions" item-text="text"
                                item-value="value" label="Sélectionnez l'arrière-plan" hide-details class="mt-2">
                                <template v-slot:selection="{ item }">
                                    <div style="display: flex; align-items: center;">
                                        <img :src="item.image" alt="Background"
                                            style="width: 35px; height: 24px; margin-right: 8px;" />
                                        {{ item.text }}
                                    </div>
                                </template>
                                <template v-slot:item="{ item }">
                                    <div style="display: flex; align-items: center;">
                                        <img :src="item.image" alt="Background"
                                            style="width: 35px; height: 24px; margin-right: 8px;" />
                                        {{ item.text }}
                                    </div>
                                </template>
                            </v-select>
                        </v-col>


                        <v-col cols="12" sm="12">
                            <v-checkbox v-model="form.active" label="Actif"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="12">
                        <v-btn :loading="loading" color="indigo" outlined @click="saveSlide()" :disabled="loading">
                            <template v-if="!loading">
                                <v-icon small class="mr-2">mdi-content-save</v-icon>
                                Enregistrer
                            </template>
                            <v-progress-circular v-else indeterminate color="white" size="20"></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-card-actions>

                <v-snackbar :timeout="timeout" :value="saveSuccess" right top color="success" tile app>
                    <strong>{{ msgSuccess }}</strong>
                    <template v-slot:action="{ attrs }">
                        <v-btn color="black" text v-bind="attrs" @click="saveSuccess = false">
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-snackbar v-model="snackbar" :color="snackbarColor" top right timeout="5000">
                    {{ snackbarMessage }}
                    <v-btn color="white" text @click="snackbar = false">Close</v-btn>
                </v-snackbar>

            </v-card>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-main>
    </v-app>
</template>

<script>
import axios from "axios";
let table = '/slides';
let token = localStorage.getItem('token');
export default {
    name: "SlideAddComponent",
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,
            form: {
                title_fr: '',
                title_ar: '',
                title_en: '',
                img_path: null,
                video_path: null,
                background: '',
                iframe: '',
                link: '',
                active: false,
            },
            backgroundOptions: [],
            loading: false,
            Url: process.env.VUE_APP_API_URL_STORAGE,
            snackbar: false,
            msgSuccess: [],
            saveSuccess: false,
            timeout: 3000,
            overlay: false,
            snackbarMessage: '', // the error message
            snackbarColor: 'error', // color of the snackbar
        }
    },
    created() {
        this.backgroundOptions = [
            { text: 'Background1', value: 'bg1', image: this.Url + 'images/slides/color1.jpg' },
            { text: 'Background2', value: 'bg2', image: this.Url + 'images/slides/color2.jpg' },
            { text: 'Background3', value: 'bg3', image: this.Url + 'images/slides/color3.jpg' },
            { text: 'Background4', value: 'bg4', image: this.Url + 'images/slides/color4.jpg' },
            { text: 'Background5', value: 'bg5', image: this.Url + 'images/slides/color5.jpg' }
        ];
    },
    methods: {
        saveSlide() {
            this.loading = true;
            let url = this.apiUrl + table;
            const object = new FormData();

            // Append text fields
            object.append('title_fr', this.form.title_fr || '');
            object.append('title_ar', this.form.title_ar || '');
            object.append('title_en', this.form.title_en || '');
            object.append('iframe', this.form.iframe || '');
            object.append('link', this.form.link || '');
            object.append('background', this.form.background || '');
            object.append('active', this.form.active ? 1 : 0);

            // Append image if exists
            const imagefile = document.querySelector('#fileImage');
            if (imagefile && imagefile.files[0]) {
                object.append("img_path", imagefile.files[0]);
            }

            // Append video if exists
            const videofile = document.querySelector('#fileVideo');
            if (videofile && videofile.files[0]) {
                object.append("video_path", videofile.files[0]);
            }

            axios.post(url, object, {
                headers: {
                    Authorization: "Bearer " + token,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                this.saveSuccess = true;
                this.msgSuccess = 'Le slide a été enregistré.';
                // Reset form fields
                this.form = {
                    title_fr: '',
                    title_ar: '',
                    title_en: '',
                    img_path: '',
                    video_path: '',
                    iframe: '',
                    link: '',
                    active: false
                };

                // Clear file inputs
                document.querySelector('#fileImage').value = '';
                document.querySelector('#fileVideo').value = '';
            }).catch((error) => {
                this.snackbarMessage = error.response.data.message || 'An error occurred while saving the slide.';
                this.snackbar = true; // Show the snackbar
                console.log(error.response);
            }).finally(() => {
                this.loading = false; // Stop loader when action completes
            });

        }


    },
    computed: {
        id() {
            return this.$route.params.id;
        }
    },
}
</script>

<style scoped></style>