<template>

    <v-app>
      <v-main class="container align-center px-1">
        <h2 class="font-weight-medium mb-2">
          <v-icon >mdi-arrow-right</v-icon>
          Villes
        </h2>
        <div>
          <v-alert
              v-model="alert"
              dismissible
              color="green"
              border="left"
              elevation="2"
              colored-border
              type="success"
          >
            <strong>{{ msgSuccess }}</strong>
          </v-alert>
        </div>
  
  
  
  
  
  
  
            <div class="d-flex">
              <v-btn color="primary" dark class="ml-auto ma-3" to="/Villes/add" v-on="on">
                New
                <v-icon small>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </div>
  
  
  
        <v-card>
  
            <v-card-title>
                
                  <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
          </v-card-title>
  
          <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              mobile-breakpoint="800"
              class="elevation-0">
            <template v-slot:[`item.actions`]="{ item }">
              <div class="btn-actions">
                
                <!-- <v-btn :to="'/Villes/view/'+item.id">
                  <v-icon small color="info"> mdi-eye</v-icon>
                </v-btn> -->
  
                <v-btn :to="'/Villes/edit/'+item.id">
                  <v-icon small color="success"> mdi-pencil</v-icon>
                </v-btn>
               
                <v-icon
                    small
                    @click="showDeleteDialog(item)"
                    color="pink"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
            <template v-slot:[`item.ville`]="{ item }">
              <div class="text-truncate" style="width: 180px">
                {{item.ville}}
              </div>
            </template>
            <template v-slot:[`item.ville_ar`]="{ item }">
              <div class="text-truncate" style="width: 380px">
                {{item.ville_ar}}
              </div>
            </template>
  
  
          </v-data-table>
          <!-- delete dialog -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Delete</v-card-title>
              <v-card-text>Voulez-vous vraiment supprimer cet élément  <strong> `{{itemToDelete.nom}}` </strong> ?</v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="dialogDelete = false">Close</v-btn>
                <v-btn color="primary" text @click="deleteItem()">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- this dialog is used for both create and update -->
        </v-card>
      </v-main>
    </v-app>
  </template>
  
  <!-- <script>
  import axios from "axios";
  let table = '/Villes';
  
  let token = localStorage.getItem('token');
  export default {
    name: "ListvillesCompenent",
    
  }
  </script> -->
  
<script>
  import axios from "axios";
  let table = '/villes';

let token = localStorage.getItem('token');
export default {
    name: "ListvillesCompenent",
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout')
  },
  data () {
    return {
        headers: [
        { text: 'Id', value: 'id' },
        { text: 'ville', value: 'ville' },
        { text: 'ville_ar', value: 'ville_ar' },
        { text: 'Action', value: 'actions', sortable: false },
      ],
      items: [],
      apiUrl:process.env.VUE_APP_API_URL,
      dialog: false,
      dialogDelete: false,
      itemToDelete: {},
      Url :process.env.VUE_APP_API_URL_STORAGE,
      snackbar: false,
      msgSuccess: [],
      alert: false,
      search:'',
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    showEditDialog(item) {
      this.editedItem = item||{}
      this.dialog = !this.dialog
    },
    loadItems() {
      this.items = []

      axios.get(this.apiUrl+'/villes',
          { headers: { Authorization: "Bearer " + token }})
          .then((response) => {
              this.items = response.data
              console.warn(this.items);
          }).catch((error) => {
        console.log(error)
      })
    },
    deleteItem() {
      console.log('deleteItem', this.itemToDelete)
      const index = this.items.indexOf(this.itemToDelete)

      axios.delete( this.apiUrl+table+`/${this.itemToDelete.id}`,
          { headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }
          }).then((response) => {
        this.items.splice(index, 1)
        this.dialogDelete = false
        this.alert = true
        this.msgSuccess = 'Le contenu a été supprimé.'
      })


      //this.items.splice(index, 1)
      //this.dialogDelete = false
    },
    showDeleteDialog(item) {
      this.itemToDelete = item
      this.dialogDelete = !this.dialogDelete
    },
  }
}
</script>
  <style scoped>
  
  </style>
  
  
  