<template>

  <v-app>
    <v-main class="container align-center px-1">
      <h2 class="font-weight-medium mb-2">
        <v-icon >mdi-arrow-right</v-icon>
        Villes
      </h2>
      <div>
        <v-alert
            v-model="alert"
            dismissible
            color="green"
            border="left"
            elevation="2"
            colored-border
            type="success"
        >
          <strong>{{ msgSuccess }}</strong>
        </v-alert>
      </div>




        <v-card>
          <v-card-title>
            
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field v-model="villes.ville" label="Nom"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field v-model="villes.ville_ar" label="Nom Arabe"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

              <v-col cols="12" sm="12">
            <v-btn color="indigo" outlined  @click="save()"><v-icon small class="mr-2">mdi-content-save</v-icon> Enregistrer</v-btn>

      
              </v-col>
          </v-card-actions>


   <v-snackbar
          :timeout="timeout"
          :value="saveSuccess"
          right
          top 
          color="success"
          tile
          app
          
        >
          <strong>{{ msgSuccess }}</strong> 

            <template v-slot:action="{ attrs }">
              <v-btn
                color="black"
                text
                v-bind="attrs"
                @click="saveSuccess = false"
              >
                Close
              </v-btn>
            </template>
        </v-snackbar>

        </v-card>
       
 


    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
// import User from "@/apis/User";
let table = '/villes';

let token = localStorage.getItem('token');
export default {
  name: "villesComponent",
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout');
    this.getUser();
  },
  data () {
    return {
     
      apiUrl:process.env.VUE_APP_API_URL,
      villes: {
            ville:'',
            ville_ar:'',
      },
      Url :process.env.VUE_APP_API_URL_STORAGE,
      snackbar: false,
      msgSuccess: [],
      alert: false,
      saveSuccess:false,
      timeout: 3000,
      user:{},
    }
  },
  mounted() {
   
  },
  methods: {
    save() {
      let method = "post"
      let url = this.apiUrl+table;
      axios[method](url,
          this.villes,
          { headers: {
              Authorization: "Bearer " + token,
            }
          }).then((response) => {        
          this.saveSuccess = true;
          this.msgSuccess = 'Le contenu a été enregistré.';
        
      }).catch((error) => {
        this.msgErreur = error;
        console.log(error.response)
      })
    },
    
  },


}
</script>

<style scoped>
#drpafficher{
  width: 100%;
  padding: 7px;
  border: 1px solid;
  margin-top: 10px;
}
</style>


