<template>

  <v-app>
    <v-main class="container align-center px-1">
      <h2 class="font-weight-medium mb-2">
        <v-icon >mdi-arrow-right</v-icon>
        Modifier l'hôtel
      </h2>
      <div>
        <v-alert
            v-model="alert"
            dismissible
            color="green"
            border="left"
            elevation="2"
            colored-border
            type="success"
        >
          <strong>{{ msgSuccess }}</strong>
        </v-alert>
      </div>




        <v-card>
          <v-card-title>
           <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->

          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12">
                <label for="drpafficher">Afficher</label>
                <select class="form-control" v-model="editedItem.display" id="drpafficher">
                  <option value="1">Oui</option>
                  <option value="0">No</option>
                </select>
              </v-col>
              <v-col cols="12" sm="12">
                <label for="drpafficher">Afficher sur la Page d'Accueil</label>
                <select class="form-control" v-model="editedItem.display_home" id="drpafficher">
                  <option value="1">Oui</option>
                  <option value="0">No</option>
                </select>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.nom" label="Nom"></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.nom_ar" label="Nom Arabe"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.nom_en" label="Nom Anglais"></v-text-field>
            </v-col>
                <v-col cols="12" sm="12">
                <v-textarea v-model="editedItem.description" label="Description"></v-textarea>
              </v-col>

                <v-col cols="12" sm="12">
                <v-textarea v-model="editedItem.description_ar" label="Description Arabe"></v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
              <v-textarea v-model="editedItem.description_en" label="Description Anglais"></v-textarea>
            </v-col>
                <v-col cols="12" sm="12">
                    <label for="drpafficher">Ville</label>
                    <select class="form-control" v-model="editedItem.ville_id"  id="drpafficher">
                        <option v-for="ville in villes" :key="ville.id" :value="ville.id" :selected="editedItem.ville_id === ville.id">
                        {{ ville.ville }}
                        </option>
                    </select>
                </v-col>
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.nbr_etoiles" label="Nombre d'étoiles"></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.tel" label="Numéro de téléphone "></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.website" label="Site web"></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.adresse" label="Adresse"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
              <v-text-field v-model="editedItem.adresse_ar" label="Adresse(AR)"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.map" label="Map"></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.instagram" label="Instagram"></v-text-field>
              </v-col>


              <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.whatsapp" label="Whatsapp"></v-text-field>
              </v-col>

                <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.facebook" label="Facebook"></v-text-field>
              </v-col>

                <v-col cols="12" sm="12">
                <v-text-field v-model="editedItem.tiktok" label="Tiktok"></v-text-field>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field v-model="editedItem.meta_titre" label="Meta titre (FR)"></v-text-field>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field v-model="editedItem.meta_titre_ar" label="Meta titre (AR)"></v-text-field>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field v-model="editedItem.meta_description" label="Meta description (FR)"></v-text-field>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field v-model="editedItem.meta_description_ar" label="Meta description (AR)"></v-text-field>
              </v-col>


        <!--
              <v-col cols="12" sm="12">
                <v-file-input
                    v-model="editedItem.image"
                    label="Image"
                    id="fileImage"
                    filled
                    prepend-icon="mdi-camera"
                ></v-file-input>

                        <div class="text-truncate text-center justify-center" :v-for="image in editedItem.images" >
                        <v-img  :src="Url+image.image" :alt="editedItem.nom" width="100px"></v-img>
                        </div>

              </v-col>
        -->

            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

              <v-col cols="12" sm="12">
            <v-btn color="indigo" outlined  @click="saveHeberegement()"><v-icon small class="mr-2">mdi-content-save</v-icon> Enregistrer</v-btn>


              </v-col>
          </v-card-actions>


   <v-snackbar
          :timeout="timeout"
          :value="saveSuccess"
          right
          top
          color="success"
          tile
          app

        >
          <strong>{{ msgSuccess }}</strong>

            <template v-slot:action="{ attrs }">
              <v-btn
                color="black"
                text
                v-bind="attrs"
                @click="saveSuccess = false"
              >
                Close
              </v-btn>
            </template>
        </v-snackbar>

        </v-card>

    <v-card class="mt-3">
          <v-card-title>
            <v-row>
              <v-col  cols="12" sm="6">
           <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
            Images :
              </v-col>
                <v-col  cols="12" sm="6" >
            <v-btn color="success" outlined dark class="float-right ma-3" @click="ajouterImage()">
              Ajouter
              <v-icon small>mdi-plus-circle-outline</v-icon>
            </v-btn>
              </v-col>
              <v-col cols="12" sm="12">
                <v-file-input
                    v-model="editedItem.image"
                    label="Image"
                    id="fileImage"
                    filled
                    multiple
                    prepend-icon="mdi-camera"
                ></v-file-input>
              </v-col>

            </v-row>
          </v-card-title>
              <v-card-text>
                <v-row>
                <v-col cols="12" lg="3" md="3" sm="4" xs="4"  align="left"  v-for="image in editedItem.images" :key="image.id" >
                        <v-img  :src="Url+image.image" :alt="editedItem.nom" style="max-height:100px;margin:auto;"></v-img>
                        <v-btn @click="showDeleteImageDialog(image)" color=""><v-icon color="red">mdi-delete</v-icon></v-btn>
                </v-col>
                </v-row>
          </v-card-text>
    </v-card>

    <v-card class="mt-3">
          <v-card-title>
           <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
            Services :
          </v-card-title>
              <v-card-text>
                     <v-row>
                        <v-list>

                              <v-subheader>
                              </v-subheader>

                              <v-list-item-group v-model="selectedColumns" multiple>
                                <v-list-item class="float-left col-lg-3 col-sm-6 col-md-4 col-xs-6" v-for="column in services" :key="column.id" :value="column.id">
                                  <template v-slot:default="{  toggle }">
                                    <v-list-item-action>
                                      <v-checkbox
                                        :input-value="selectedColumns.includes(column.id)"
                                        :true-value="column.id"
                                        color="primary"
                                        @click="toggle"
                                      />
                                    </v-list-item-action>

                                    <v-list-item-title>
                                      {{ column.nom }}
                                    </v-list-item-title>
                                  </template>
                                </v-list-item>
                              </v-list-item-group>

                       </v-list>


                       <v-spacer></v-spacer>
                       <v-btn color="info" outlined dark class="ml-auto ma-3" @click="updateServices()">

                          <v-icon small>mdi-content-save</v-icon> Modifier
                        </v-btn>


                </v-row>

          </v-card-text>
    </v-card>

    <v-card class="mt-3">
          <v-card-title>
           <!-- <span v-if="editedItem.id">Edit {{editedItem.id}}</span>-->
            Chambres :
            <v-spacer></v-spacer>


       <v-dialog v-model="dialog" max-width="500px">

       <template v-slot:activator="{ on }">
          <div class="d-flex">
            <v-btn color="primary" dark class="ml-auto ma-3" v-on="on">
              Ajouter
              <v-icon small>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </template>

      <v-card>
        <v-card-title>
          <span v-if="editedObj.id">Edit {{editedObj.id}}</span>
          <span v-else>Create</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <!--<v-text-field v-model="editedObj.type" label="Type"></v-text-field>-->
               <v-autocomplete
               v-model="editedObj.type"
                :items="optionsType"
                label="Type"
                no-filter
                ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea v-model="editedObj.options" label="Options"></v-textarea>
             <!--  <v-autocomplete
               v-model="editedObj.options"
                :items="optionsListe"

                label="Options"
                multiple
                ></v-autocomplete>-->
            </v-col>

            <v-col cols="12" sm="12">
              <v-text-field type="number" v-model="editedObj.prix" label="Prix"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue darken-1" text @click="showEditDialog()">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveItem(editedObj)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



          </v-card-title>
              <v-card-text>
                <v-row>
                <v-col cols="12" sm="12"  >


                          <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      Type
                                    </th>
                                       <th class="text-left">
                                     Options
                                    </th>
                                    <th class="text-left">
                                      Prix
                                    </th>
                                    <th class="text-left">
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="item in editedItem.chambres"
                                    :key="item.id"
                                  >
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.options }}</td>
                                    <td>{{ item.prix }}</td>
                                    <td>

                                    <v-icon  small class="mr-2" @click="showEditDialog(item)" color="primary"  > mdi-pencil </v-icon>
                                    <v-icon small @click="showDeleteDialog(item)" color="pink"> mdi-delete </v-icon>

                                    </td>

                                  </tr>
                                </tbody>
                              </template>
                         </v-simple-table>



                </v-col>
                </v-row>
          </v-card-text>
    </v-card>



            <!-- delete dialog image -->
        <v-dialog v-model="dialogImageDelete" max-width="500px">
          <v-card>
            <v-card-title>Delete Image</v-card-title>
            <v-card-text>Voulez-vous vraiment supprimer cet élément  ?</v-card-text>
              <v-img  :src="Url+imageToDelete.image"  style="max-height:100px;max-width:100px;margin:auto;"></v-img>
            <v-card-actions>
              <v-btn color="primary" text @click="dialogImageDelete = false">Close</v-btn>
              <v-btn color="primary" text @click="deleteImage()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

            <!-- delete dialog chambre -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title>Delete</v-card-title>
            <v-card-text>Voulez-vous vraiment supprimer cet élément  <strong> `{{itemToDelete.type}}` </strong> ?</v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="dialogDelete = false">Close</v-btn>
              <v-btn color="primary" text @click="deleteItem()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

<v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>


    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import api from "@/apis/Api";
let table = '/hotels';

let token = localStorage.getItem('token');
export default {
  name: "HotelComponent",
  created() {
    this.$store.commit('SET_LAYOUT', 'app-layout');
    this.listeServices();
  },
  data () {
    return {
      optionsType:['Chambre individuelle','Chambre double','Suite'],
      optionsListe: ['Demi-pension (déjeuner)','Demi-pension (dîner)','Pension complète','Tout Inclus'],
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nom', value: 'nom' },
        { text: 'Nom_ar', value: 'nom_ar' },
        { text: 'Nom_en', value: 'nom_en' },
        { text: 'Description', value: 'description' },
        { text: 'Description_ar', value: 'description_ar' },
        { text: 'Description_en', value: 'description_en' },
        { text: 'Image', value: 'image' , sortable: false, width:"100"},
        { text: 'Action', value: 'actions', sortable: false },
      ],
      items: [],
      apiUrl:process.env.VUE_APP_API_URL,
      dialog: false,
      editedItem: {},
      dialogDelete: false,
      itemToDelete: {},
      Url :process.env.VUE_APP_API_URL_STORAGE,
      snackbar: false,
      msgSuccess: [],
      alert: false,
      saveSuccess:false,
      timeout: 3000,
      services:[],
      villes:[],
      selectedColumns:[],
      editedObj:[],
      imageToDelete:{},
      dialogImageDelete:false,
      overlay:false,
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    showEditDialog(item) {
      this.editedObj = item||{}
      this.dialog = !this.dialog
    },
    loadItems() {
      this.items = []

      axios.get(this.apiUrl+table+`/id/${this.id}`,
          { headers: { Authorization: "Bearer " + token }})
          .then((response) => {
            console.warn(response.data);
            this.editedItem = response.data.hotels;
            this.villes = response.data.villes;
            this.selectedColumns = this.editedItem.hotel_service.map(
            (column) => parseInt(column.id_service)
          );

          }).catch((error) => {
        console.log(error)
      })
    },
    saveHeberegement(item) {
      /* this is used for both creating and updating API records
       the default method is POST for creating a new item */

      let method = "post"
      let id =   this.editedItem.id
      let url = this.apiUrl+table+`/${id}`;

      // save the record
      axios[method](url,
          this.editedItem,
          { headers: {
              Authorization: "Bearer " + token,
              //'Content-Type': 'multipart/form-data'
              //'Content-Type': imagefile.type
            }
          }).then((response) => {


          // add new item to state
          this.saveSuccess = true;
          this.msgSuccess = 'Le contenu a été enregistré.';

      }).catch((error) => {
        this.msgErreur = error;
        console.log(error.response)
      })
    },
    saveItem(item) {
      /* this is used for both creating and updating API records
       the default method is POST for creating a new item */

      let method = "post"
      let url = this.apiUrl+'/chambres'
      let id = item.id
      // airtable API needs the data to be placed in fields object
      let data = {
        fields: item
      }
      const object = new FormData ();
      object.append ('type', data.fields.type);
      object.append ('options', data.fields.options.toString());
      object.append ('prix', data.fields.prix);
      object.append ('id_hotel', this.editedItem.id);

      if (id) {
        // if the item has an id, we're updating an existing item
        method = "post"
        url = this.apiUrl+'/chambres/update'+`/${id}`

        // must remove id from the data for airtable patch to work
        delete data.fields.id
      }

      // save the record
      axios[method](url,
          object,
          { headers: {
              Authorization: "Bearer " + token,
              //"Content-Type": "application/json"
              //'Content-Type': 'multipart/form-data'
              //'Content-Type': imagefile.type
            }
          }).then((response) => {
        if (response.data && response.data.id) {

          // add new item to state
          this.editedObj.id = response.data.id
          if (!id) {
            // add the new item to items state
            this.editedObj.options = item.options.toString();
            this.editedItem.chambres.push(this.editedObj)
          }
          this.saveSuccess = true;
          this.msgSuccess = 'Le contenu a été enregistré.';
          this.editedObj = {}
        }
        this.dialog = !this.dialog
      }).catch((error) => {
        this.msgErreur = error;
        console.log(error.response)
      })
    },
    deleteItem() {
      console.log('deleteItem', this.itemToDelete)
      const index = this.editedItem.chambres.indexOf(this.itemToDelete)

      axios.delete( this.apiUrl+'/chambres'+`/${this.itemToDelete.id}`,
          { headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }
          }).then((response) => {
        this.editedItem.chambres.splice(index, 1);
        this.dialogDelete = false;
        this.saveSuccess = true;
        this.msgSuccess = 'Le contenu a été supprimé.'
      })


      //this.items.splice(index, 1)
      //this.dialogDelete = false
    },
    showDeleteDialog(item) {
      this.itemToDelete = item
      this.dialogDelete = !this.dialogDelete
    },
     showDeleteImageDialog(item) {
      this.imageToDelete = item
      this.dialogImageDelete = !this.dialogImageDelete
    }
    ,
    deleteImage() {
     // console.log('deleteItem', this.imageToDelete)
     // const index = this.items.indexOf(this.imageToDelete)

      axios.delete( this.apiUrl+'/images'+`/${this.imageToDelete.id}`,
          { headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/json"
            }
          }).then((response) => {
        //this.imageToDelete.images.splice(index, 1)

        this.dialogImageDelete = false;
        this.saveSuccess = true;
        this.msgSuccess = 'Le contenu a été supprimé.';
        this.$router.go();
      })
      },
      ajouterImage(){

      this.overlay = true;
      const object = new FormData ()
      object.append ('id_hotel', this.editedItem.id)
      var imagefile = document.querySelector('#fileImage');


      for (let i = 0; i < imagefile.files.length; i++) {
        object.append("images[]", imagefile.files[i]);
      }

      axios.post( this.apiUrl+'/images',object,
          { headers: {
              Authorization: "Bearer " + token,
              'Content-Type': imagefile.type
            }
          }).then((response) => {
        this.overlay = false;
        this.msgSuccess = 'Le contenu a été enregistré.';
        this.$router.go();

      }).catch(()=>{
       this.overlay = false;
      }).finally(()=>{
        this.overlay = false;
      })



      },
      listeServices(){
        axios.get(this.apiUrl+"/services",
                { headers: { Authorization: "Bearer " + token }})
                .then((response) => {
                  console.warn(response.data);
                  this.services = response.data;
                }).catch((error) => {
                  console.log(error)
            })
       },

  updateServices(){

              var formData = new FormData();
                //console.log(this.checked);
                for (var i = 0; i < this.selectedColumns.length; i++) {
                  formData.append('services[]', this.selectedColumns[i]);
                }
                formData.append('id_hotel',this.editedItem.id);

         axios.post(this.apiUrl+'/hotel-liste-services',formData,
                { headers: { Authorization: "Bearer " + token }})
                .then((response) => {
                  console.warn(response.data);
                      this.saveSuccess = true;
                      this.msgSuccess = 'Le contenu a été enregistré.';
                }).catch((error) => {
                  console.log(error)
            })

  }

  },

  computed: {
    id() {
      // We will see what `params` is shortly
      return this.$route.params.id
    }
  },
}
</script>

<style scoped>
#drpafficher{
  width: 100%;
  padding: 7px;
  border: 1px solid;
  margin-top: 10px;
}
</style>

